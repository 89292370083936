/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Path } from '../models/Path';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PathService {
  /**
   * Retrieves the collection of Path resources.
   * Retrieves the collection of Path resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns Path Path collection
   * @throws ApiError
   */
  public static getPaths(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<Path>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/paths',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a Path resource.
   * Creates a Path resource.
   * @param requestBody The new Path resource
   * @returns Path Path resource created
   * @throws ApiError
   */
  public static createPath(
    requestBody: Path,
  ): CancelablePromise<Path> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/paths',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a Path resource.
   * Retrieves a Path resource.
   * @param id Path identifier
   * @returns Path Path resource
   * @throws ApiError
   */
  public static getPath(
    id: string,
  ): CancelablePromise<Path> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/paths/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the Path resource.
   * Replaces the Path resource.
   * @param id Path identifier
   * @param requestBody The updated Path resource
   * @returns Path Path resource updated
   * @throws ApiError
   */
  public static updatePath(
    id: string,
    requestBody: Path,
  ): CancelablePromise<Path> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/paths/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the Path resource.
   * Removes the Path resource.
   * @param id Path identifier
   * @returns void
   * @throws ApiError
   */
  public static deletePath(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/paths/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
