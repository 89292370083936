import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import { Form } from '../../components/common/form/form'
import { signupForm } from '../../forms/user-forms'
import { elsewhereToast } from '../../components/common/toast/toast'
import { getLocales } from 'react-native-localize'
import { FULLY_SUPPORTED_LANGUAGES } from '../../i18n/config'
import { useSelector } from '../../ducks/root-reducer'
import { DEFAULT_GENDER_ID } from '../../constants/ui-constants'
import {
  UserSignupRequest,
  UserSignupRequestService,
} from '../../../../api/_openapi'
import { selectGenderOptions } from '../../ducks/ui/ui'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../navigation/types'
import { RouteProp } from '@react-navigation/native'
import { RootStackParamList } from '../../navigation/types'
import { SupportedLanguage } from '../../../../api/frontend-types'
import { gtag_report_conversion } from '../../modules/google-ads/google-ads-helpers'
import { CheckboxGroupWithFreeOptionType } from '../../components/common/form/form-field'

export type SignupNavigationProp = RootStackNavigationProp<'Signup'>
export type SignupRouteProp = RouteProp<RootStackParamList, 'Signup'>

export default function Signup({
  navigation,
  route,
}: RootStackScreenProps<'Signup'>) {
  // Get user's language from phone
  // Get current language
  const locale = getLocales()[0]
  const languageTag = locale.languageTag || 'en-US'
  const inviteCode = route.params?.inviteCode

  const { t } = useTranslation()
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false)

  const genderOptions = useSelector(selectGenderOptions)
  const defaultGenderId =
    genderOptions?.find((x: any) => x.label.includes('not to answer'))?.value ||
    DEFAULT_GENDER_ID

  // Get user's language from phone
  // We mostly don't support regional variants,
  // so just take the first two letters
  // Other than zh-Hant, which we do support
  // We only want the 'fully supported languages' here
  const userLanguage = useMemo(() => {
    // Format whatever language the user's phone is set to
    let deviceLangCodeFormatted = 'en'
    if (languageTag.includes('zh-Hant')) {
      deviceLangCodeFormatted = 'zh-Hant'
    } else if (languageTag) {
      deviceLangCodeFormatted = languageTag.substring(0, 2) as SupportedLanguage
    }
    // If the user's phone language is supported, use it
    if (FULLY_SUPPORTED_LANGUAGES.includes(deviceLangCodeFormatted)) {
      return deviceLangCodeFormatted
    } else {
      // Otherwise, use English
      return 'en'
    }
  }, [languageTag])

  const onSubmitForm = (
    data: Omit<UserSignupRequest, 'marketingEmails|gender'> & {
      gender: CheckboxGroupWithFreeOptionType
      marketingEmails: string[]
    },
  ) => {
    // Tell Google that someone has converted
    gtag_report_conversion(undefined)

    // Get src from local storage, if it's there
    // (if web)
    let src: string | null = ''
    if (Platform.OS === 'web') {
      src = localStorage.getItem('src')
    }

    setCreatingAccount(true)
    // Use default gender (Prefer not to answer) if none selected
    const {
      gender,
      email,
      username,
      country,
      birthdate,
      personalityType,
      marketingEmails,
      pronouns,
    } = data

    let userGender,
      genderDisplay = ''
    const { values = [], freeOption = '', freeOptionText = '' } = gender

    if (gender) {
      if (values[0] === freeOption && freeOptionText) {
        userGender = freeOption
        genderDisplay = freeOptionText
      } else {
        userGender = values[0] || defaultGenderId
      }
    }

    const requestBody = {
      email,
      username,
      gender: userGender,
      genderDisplay,
      country,
      birthdate,
      personalityType,
      pronouns,
      language: userLanguage,
      marketingEmails: marketingEmails?.length > 0 || false,
    } as UserSignupRequest

    UserSignupRequestService.signupRequest(requestBody, true)
      .then(() => {
        navigation.replace('Login', {
          email: requestBody.email,
          codeSent: true,
          inviteCode,
        })
        setCreatingAccount(false)
        elsewhereToast.showToast({
          description: 'toast.signUpSuccess.title',
          status: 'success',
          duration: 2000,
        })
      })
      .catch((err) => {
        console.log('Signup error', err)
        elsewhereToast.showToast({
          title: 'toast.signUpError.title',
          description: err.message,
          status: 'error',
        })
        setCreatingAccount(false)
      })
  }

  return (
    <Form
      name={'signupForm'}
      form={signupForm}
      onSubmit={onSubmitForm}
      headerTitle={t('common.signUp')}
      prepopulateValues={{
        email: '',
        username: '',
        gender: {
          freeOption: '0186c294-124d-7022-8c29-e523505a42d0',
          freeOptionText: '',
          values: [],
        },
        country: '',
        birthdate: '',
        personalityType: '',
        marketing: [],
        terms: [],
      }}
      loading={creatingAccount}
      loadingLabel={t('loading.signUp')}
    />
  )
}
