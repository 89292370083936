import React, { useState, useEffect } from 'react'
import { useColorModeValue, Badge, useDisclose, Spinner } from 'native-base'
import { Platform } from 'react-native'
import {
  LARGE_LINE_HEIGHT,
  LG_FONTSIZE_PIXELS,
  LINE_WIDTH,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { PaddedContentArea } from '../../layout/content-area-padded'
import { ButtonPill } from '../../common/buttons/button-pill'
import { useTranslation } from 'react-i18next'
import { PurchasesPackage, GoogleProductChangeInfo } from '../../../revenuecat'
import { ActionSheetMenu } from '../../common/action-sheet/action-sheet-menu'
import { useSelector } from '../../../ducks/root-reducer'
import { useDispatch } from 'react-redux'
import { SansText } from '../../common/copy/text-sans'
import { selectIsPurchasing, setIsPurchasing } from '../../../ducks/ui/ui'
import {
  extractSubscriptionStatusFromCustomerInfo,
  loadSubscriptionStatus,
  refreshSubscriptionStatus,
  updateSubscriptionStatus,
} from '../../../ducks/subscription/thunks/subscription-thunks'
import { TabProps } from '../../layout/tab-view-tabs'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  IconCoin,
  IconGem,
} from '../../../assets/react-native-svg/icons/icons-ui'
import { getColorForStatus } from '../../../modules/ui-helpers/ui-helpers'
import { selectUser } from '../../../ducks/user/user'
import { FEW } from '../../../i18n/config'
import { cap } from '../../../modules/strings/string-helpers'
import { HeadingMain } from '../../common/copy/heading-main'
import { Row } from '../../common/row/row'
import { MarkdownText } from '../../common/copy/markdown-text'
import i18n from '../../../i18n/i18nnext'
import { getBodyFont } from '../../../modules/language-helpers/language-helpers'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'
import { View } from '../../common/view/view'
import {
  getGoogleProductChangeInfo,
  getIdentifier,
  getOfferingsFromRevenueCat,
  renderOptions,
  purchasePackage,
} from './helpers'
import { renderManageSubscriptions } from './manage-subscriptions'
import { MEDIUM_FONT_SIZE } from '../../../constants/constants'

enum FeatureOffering {
  free = 'free',
  credits = 'credits',
  premium = 'premium',
  supporter = 'supporter',
}
export type FeatureSlide = TabProps<FeatureOffering> & {
  SvgIcon?: ({ color, size }: { color: string; size: number }) => JSX.Element
  key: FeatureOffering
  title?: string
  features: string
  defaultValue?: string
  offerings: PurchasesPackage[]
  name: FeatureOffering
}

type Offerings = {
  premium?: PurchasesPackage[]
  supporter?: PurchasesPackage[]
  credits?: PurchasesPackage[]
}

export const Feature = React.memo(
  ({
    feature,
    triggerConfetti,
  }: {
    feature: keyof typeof FeatureOffering
    triggerConfetti: () => void
  }) => {
    // I18N
    const { t } = useTranslation()
    const lang = i18n.resolvedLanguage || 'en'
    const bodyFont = getBodyFont(lang)

    const user = useSelector(selectUser)
    let {
      subscriptionTier = 'free',
      subscriptionInfo = null,
      creditsAvailable,
    } = user || {}
    const isPurchasing = useSelector(selectIsPurchasing)
    const [loading, setLoading] = useState<boolean>(true)
    const [offerings, setOfferings] = useState<Offerings>({})

    const featureObject: {
      [key in FeatureOffering]: {
        title: string
        features: string
        defaultValue?: string
        showCTA: boolean
      }
    } = {
      free: {
        title: t('subscriptions.tiers.free'),
        features: t(`subscriptions.benefits.free`),
        defaultValue: undefined,
        showCTA: false,
      },
      premium: {
        title: t('subscriptions.tiers.premium'),
        features: t(`subscriptions.benefits.premium`),
        defaultValue: 'premium_monthly',
        showCTA: subscriptionTier === 'free',
      },
      supporter: {
        title: t('subscriptions.tiers.supporter'),
        features: t(`subscriptions.benefits.supporter`),
        defaultValue: 'supporter_monthly',
        showCTA:
          subscriptionTier === 'free' ||
          (subscriptionTier === 'premium' && feature === 'supporter'),
      },
      credits: {
        title: cap(t('common.credit_plural', { count: FEW })),
        features: t('settings.credits.description'),
        defaultValue: 'credits_15',
        showCTA: true,
      },
    }
    const { defaultValue, title, features } =
      featureObject[feature as FeatureOffering]

    const [value, setValue] = useState(defaultValue)
    const color = useColorModeValue(BLACK, WHITE)
    const colorScheme = useColorModeValue('light', 'dark')
    const { isOpen, onOpen, onClose } = useDisclose()
    const dispatch = useDispatch<any>()

    const openChoicesOrDialog = () => {
      getOfferings()
      onOpen()
    }

    const onSelection = async (
      packageToPurchase: PurchasesPackage,
      upgradeFromPackage?: PurchasesPackage,
    ) => {
      onClose()
      try {
        let googleProductChangeInfo: GoogleProductChangeInfo | undefined
        if (upgradeFromPackage) {
          googleProductChangeInfo =
            getGoogleProductChangeInfo(upgradeFromPackage)
        }
        const identifier = getIdentifier(packageToPurchase)

        const { customerInfo }: any = await purchasePackage(
          packageToPurchase,
          googleProductChangeInfo,
          user?.email,
        )

        if (
          typeof customerInfo.entitlements.active[identifier] !== 'undefined'
        ) {
          return
        }
        if (customerInfo && user) {
          // await dispatch(setPurchasedPackage(purchasePackage))
          const newSubscriptionStatus =
            await extractSubscriptionStatusFromCustomerInfo(customerInfo)
          await dispatch(
            updateSubscriptionStatus(user?.id, newSubscriptionStatus),
          )
          dispatch(refreshSubscriptionStatus())
        }
      } catch (e: any) {
        if (!e.userCancelled) {
          console.error('onSelection error', e)
        }
        dispatch(setIsPurchasing(false))
      }
    }

    const getSelectedOption = (
      value: string,
      tier: string,
    ): PurchasesPackage => {
      // @ts-ignore
      return offerings[tier].find((pp: PurchasesPackage) => {
        const identifier = getIdentifier(pp)
        return identifier === value
      })
    }

    const purchaseItem = async (upgrade = false) => {
      await dispatch(setIsPurchasing(true))
      try {
        const selected = getSelectedOption(value as string, feature)
        // local is only used for Android upgrades only offering upgrades from premium to supporter, so local can only apply to premium
        const upgradeFrom =
          Platform.OS === 'android' && upgrade && offerings.premium
            ? getSelectedOption('premium_monthly', 'premium')
            : undefined
        await onSelection(selected, upgradeFrom)
      } catch (e: any) {
        dispatch(setIsPurchasing(false))
        console.log('purchaseItem error', e)
      }
    }

    const getOfferings = async () => {
      try {
        const offeringsFromRevCat = await getOfferingsFromRevenueCat()

        if (
          (offeringsFromRevCat.current !== null &&
            offeringsFromRevCat.current.availablePackages.length > 0) ||
          (offeringsFromRevCat.all !== null &&
            Object.keys(offeringsFromRevCat.all).length > 0)
        ) {
          Object.keys(offeringsFromRevCat.all).forEach(
            (entitlement: string) => {
              setOfferings((prev) => ({
                ...prev,
                [entitlement]:
                  offeringsFromRevCat?.all[entitlement].availablePackages,
              }))
            },
          )
        }
      } catch (e: any) {
        console.error('Error getting offers', e.message)
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      if (isPurchasing) {
        dispatch(setIsPurchasing(false))
        // Add small delay to ensure UI updates first
        setTimeout(() => {
          triggerConfetti()
        }, 800)
      }
    }, [creditsAvailable, subscriptionTier])

    useEffect(() => {
      // Get current available packages
      const loadSubscriptionInfo = async () => {
        try {
          if (user) {
            await dispatch(loadSubscriptionStatus(user))
          }
          await dispatch(setIsPurchasing(false))
        } catch (e: any) {
          console.error('Error getting offers', e.message)
        } finally {
          setLoading(false)
        }
      }
      loadSubscriptionInfo()
    }, [])

    if (typeof subscriptionInfo === 'string') {
      subscriptionInfo = JSON.parse(subscriptionInfo)
    }

    const isCredits = feature === 'credits'
    const isOnFreeTier = subscriptionTier === 'free'
    const isUpgradable =
      subscriptionTier === 'premium' &&
      feature === 'supporter' &&
      (subscriptionInfo?.store !== 'RC_BILLING' || !subscriptionInfo?.store) &&
      ((subscriptionInfo?.store === 'PLAY_STORE' &&
        Platform.OS === 'android') ||
        (subscriptionInfo?.store === 'APP_STORE' && Platform.OS === 'ios'))

    return (
      <>
        <PaddedContentArea flex={1}>
          <Column
            flex={1}
            m={0}
            p={1}
            alignItems="center"
            justifyContent={'flex-start'}
            maxWidth={WEB_MAX_WIDTH}
          >
            <Box minHeight={150} alignItems={'center'} justifyContent="center">
              <Box
                m={0}
                alignItems="center"
                alignContent={'center'}
                justifyContent="center"
              >
                <HeadingMain m={0} p={0} textAlign={'center'}>
                  {title?.toLocaleUpperCase()}
                </HeadingMain>
              </Box>
              <View
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                paddingBottom={8}
              >
                {feature === 'credits' || feature === subscriptionTier ? (
                  <Row mt={3} space={2}>
                    <Badge
                      alignItems="center"
                      rounded="full"
                      colorScheme="success"
                      variant="outline"
                      borderWidth={LINE_WIDTH}
                      pl={3}
                      pr={3}
                      pt={'10px'}
                      pb={'8px'}
                      leftIcon={
                        feature === 'credits' ? (
                          <IconCoin
                            size={5}
                            color={getColorForStatus('success')}
                          />
                        ) : (
                          <IconGem
                            size={5}
                            color={getColorForStatus('success')}
                            mt={-0.5}
                          />
                        )
                      }
                      rightIcon={
                        feature === 'credits' ? (
                          <Row alignItems="center">
                            <SansText
                              fontSize="md"
                              color={getColorForStatus('success')}
                              alignItems="center"
                            >
                              {t('settings.credits.balance', {
                                balance: '',
                              })}
                            </SansText>
                            {loading || isPurchasing ? (
                              <Spinner
                                alignSelf="center"
                                size={MEDIUM_FONT_SIZE}
                                color={getColorForStatus('success')}
                              />
                            ) : (
                              <SansText
                                fontSize="md"
                                color={getColorForStatus('success')}
                                alignItems="center"
                              >
                                {creditsAvailable || 0}
                              </SansText>
                            )}
                          </Row>
                        ) : (
                          <SansText
                            fontSize="md"
                            color={getColorForStatus('success')}
                          >
                            {t(`subscriptions.yourCurrentTier`)}
                          </SansText>
                        )
                      }
                    />
                  </Row>
                ) : null}
              </View>
            </Box>
            <Box w="100%" m={0}>
              {
                <MarkdownText
                  style={{
                    body: {
                      fontFamily: bodyFont,
                      fontWeight: '400',
                      fontSize: LG_FONTSIZE_PIXELS,
                      lineHeight: LARGE_LINE_HEIGHT,
                      color,
                      marginBottom: 12,
                    },
                    link: {
                      marginBottom:
                        Platform.OS === 'android' ? -6.75 : undefined,
                    },
                  }}
                >
                  {features || ''}
                </MarkdownText>
              }
            </Box>
          </Column>
        </PaddedContentArea>
        <Box w="100%" m={0} maxHeight={120} flex={1}>
          <PaddedContentArea
            flexDirection={'column'}
            justifyContent={'flex-end'}
            w="100%"
            m={0}
          >
            {
              <>
                {featureObject[feature].showCTA &&
                  (isCredits || isOnFreeTier || isUpgradable) && (
                    <ButtonPill
                      type={'primary'}
                      onPress={openChoicesOrDialog}
                      isDisabled={isOpen}
                      isLoading={isPurchasing || loading}
                      mb={2}
                    >
                      {isCredits
                        ? t('subscriptions.cta.purchase')
                        : isOnFreeTier
                        ? t('subscriptions.cta.subscribe')
                        : isUpgradable
                        ? t('subscriptions.cta.upgrade')
                        : null}
                    </ButtonPill>
                  )}

                <Row justifyContent={'center'} alignItems="center">
                  <Column
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {renderManageSubscriptions(
                      Platform.OS,
                      subscriptionInfo?.store as string,
                      feature,
                      subscriptionTier as string,
                      subscriptionInfo,
                    )}
                  </Column>
                </Row>
              </>
            }
          </PaddedContentArea>
        </Box>
        <ActionSheetMenu
          isOpen={isOpen}
          onClose={onClose}
          menuItems={[]}
          content={
            <Column px={3}>
              {feature !== 'free' &&
                renderOptions(
                  offerings[feature],
                  feature,
                  value,
                  setValue,
                  isPurchasing,
                  colorScheme,
                )}
              <Box>
                <ButtonPill
                  isDisabled={isPurchasing}
                  isLoading={isPurchasing || loading}
                  type={'primary'}
                  onPress={() => {
                    const upgrade =
                      subscriptionTier === 'premium' && feature === 'supporter'
                    purchaseItem(upgrade)
                  }}
                >
                  {t('common.continue')}
                </ButtonPill>
              </Box>
            </Column>
          }
        />
      </>
    )
  },
)

Feature.displayName = 'Feature'
