import React, { useState } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { useDispatch } from 'react-redux'
import {
  addReactionToDream,
  deleteDream,
} from '../../../ducks/dream-tag/thunks/dream-thunks'
import { useTranslation } from 'react-i18next'
import Clipboard from '@react-native-clipboard/clipboard'
import { elsewhereToast } from '../../common/toast/toast'
import { useUserDate } from '../../../hooks/useUserDate'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { idEquals } from '../../../ducks/helpers'
import { unshareDreamFromGroups } from '../../../ducks/groups/thunks/group-thunks'
import { DreamFeedListNavigationProp } from './feed-list-dream'
import {
  DreamerViewNavigationProp,
  DreamerViewRouteProp,
} from '../../../screens/Dreamer/DreamerView'
import {
  SearchComponentNavigationProp,
  SearchComponentRouteProp,
} from '../../../screens/Search/search-inner'
import {
  TagViewNavigationProp,
  TagViewRouteProp,
} from '../../../screens/TagView'
import { FeedRouteProp } from '../../../screens/Feed/GroupFeed'
import { PrivateDraftsFeedRouteProp } from '../../../screens/Drafts/DraftsFeed'
import { RootStackParamList } from '../../../navigation/types'
import { FastDream, FastUser } from '../../../../../api/_openapi'
import { FeedEntryGeneric } from './feed-entry-generic'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUserMembershipInGroup } from '../../../ducks/groups/groups'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import { SM_THUMB_RES } from '../../../constants/ui-constants'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { PrivateDreamerPath } from '../../../screens'
import { PrivateDreamerPathRouteProp } from '../../../screens/PrivateDreamerPaths/PathView'

export type FeedItemDreamNavigationProp =
  | DreamFeedListNavigationProp
  | DreamerViewNavigationProp
  | SearchComponentNavigationProp
  | TagViewNavigationProp
export type FeedItemDreamRouteProp =
  | DreamerViewRouteProp
  | TagViewRouteProp
  | SearchComponentRouteProp
  | FeedRouteProp
  | PrivateDraftsFeedRouteProp
  | PrivateDreamerPathRouteProp

type FeedItemDreamProps = {
  route: FeedItemDreamRouteProp
  dream: FastDream
  index: number
  privateGroupId?: string
  currentGroup?: any
  appUser: FastUser | null
  activeGroupId: string
  showType?: boolean
}

const FeedItemDream = ({
  route,
  dream,
  index,
  privateGroupId,
  currentGroup,
  appUser,
  activeGroupId,
  showType = false,
}: FeedItemDreamProps) => {
  // console.log('FeedItemDream rendered', dream)
  // STATE
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const [unshareAlertOpen, setUnshareAlertOpen] = useState(false)

  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const navigation = useNavigation<FeedItemDreamNavigationProp>()
  const { dateString } = useUserDate(dream?.date as string)

  // VARS
  const dreamLanguage = dream.languageCode as SupportedLanguage
  const isDraft = Boolean(dream.isDraft)
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)
  const confirmMessage = dream.title
    ? t('deleteDreamDialog.description.hasTitle', { titleParam: dream.title })
    : t('deleteDreamDialog.description.noTitle')
  const isLibrary = route.name === 'LibraryDreamerView'
  const isSearch = route.name === 'PrivateSearch'
  const editLabel = isDraft ? t('editDraftPage.header') : t('common.edit')
  const isUsersOwnDream = idEquals(dream.userId, appUser?.id)

  // TODO: code below is duplicated in /dream-header
  const copyToClipboard = async () => {
    Clipboard.setString(
      `${t('common.dream_plural', { count: 1 }).toLocaleUpperCase()}:\n\n` +
        (dream.title || dateString) +
        '\n\n' +
        dream?.description +
        (dream?.note
          ? `\n\n${t('common.note').toLocaleUpperCase()}:\n\n` + dream?.note
          : ''),
    )
    elsewhereToast.showToast({
      description: t('toast.copyToClipboard.title'),
      status: 'success',
    })
  }

  // const activeUserId = authorId || userId || null
  const membership = useSelector(
    selectUserMembershipInGroup(dream.userId, activeGroupId),
  )

  let linkToScreen = 'PrivateDreamView'
  if (isLibrary) {
    linkToScreen = 'LibraryDreamView'
  } else if (isDraft) {
    linkToScreen = 'PrivateDraftView'
  } else if (dream.type === 'journal-entry') {
    linkToScreen = 'PrivateDiaryView'
  } else if (isUserPrivateGroup || isSearch) {
    linkToScreen = 'PrivateDreamView'
  } else {
    linkToScreen = 'GroupDreamView'
  }

  const linkObject = isLibrary
    ? {
        screen: linkToScreen as keyof RootStackParamList,
        params: {
          username: route?.params?.username,
          id: dream.id,
        },
      }
    : {
        screen: linkToScreen as keyof RootStackParamList,
        params: {
          id: dream.id,
          groupId: isUserPrivateGroup ? undefined : currentGroup?.id,
        },
      }

  const userInfoLinkObject = {
    screen: isUserPrivateGroup
      ? 'PrivateDreamerView'
      : ('GroupDreamerView' as keyof RootStackParamList),
    params: {
      groupId: isUserPrivateGroup ? undefined : activeGroupId,
      userId: membership?.userId || appUser?.id,
    },
  }

  const userInfoLinkProps = {
    to: userInfoLinkObject,
  }

  // @ts-ignore
  const { onPress: avatarOnPress } = useLinkProps(userInfoLinkProps)

  const actionSheetMenuItems = [
    ...(!isUsersOwnDream
      ? []
      : [
          {
            label: editLabel,
            closesMenu: true,
            onPress: () => {
              isDraft
                ? navigation.push('PrivateDraftEdit', {
                    id: dream.id as string,
                  })
                : navigation.push(
                    dream.type === 'journal-entry'
                      ? 'PrivateDiaryEdit'
                      : 'PrivateDreamEdit',
                    {
                      id: dream.id as string,
                    },
                  )
            },
          },
        ]),
    ...(!isUserPrivateGroup
      ? []
      : [
          {
            label: t('common.delete'),
            closesMenu: true,
            onPress: () => {
              setDeleteAlertOpen(true)
            },
          },
        ]),
    ...(isDraft || !isUsersOwnDream || dream.type !== 'dream'
      ? []
      : [
          {
            label: t('common.share'),
            closesMenu: true,
            onPress: () => {
              navigation.push('PrivateDreamShare', {
                id: dream.id as string,
              })
            },
          },
        ]),
    ...(isUserPrivateGroup || !isUsersOwnDream
      ? []
      : [
          {
            label: t('common.unshare'),
            closesMenu: true,
            onPress: () => {
              setUnshareAlertOpen(true)
            },
          },
        ]),
    {
      label: t('common.copyToClipboard'),
      closesMenu: true,
      onPress: copyToClipboard,
    },
    {
      label: t('common.cancel'),
      closesMenu: true,
      onPress: () => {},
    },
  ]

  const addOrRemoveReaction = () => {
    dispatch(
      addReactionToDream(dream.id, dream.shareId, dream.userReactionCount),
    )
  }

  const avatarImgUrl = updateCloudinaryUrlForDisplay(
    membership?.imageUrl,
    SM_THUMB_RES,
  )

  return (
    <>
      <FeedEntryGeneric
        language={dreamLanguage}
        titleLanguage={dreamLanguage}
        inGroup={
          currentGroup && privateGroupId && privateGroupId !== currentGroup.id
        }
        type={showType ? dream.type : undefined}
        title={dream.title}
        date={dream.date}
        displayDate={dream.displayDate}
        description={dream.description}
        imageUrl={dream.imageUrl}
        actionSheetCanOpen={true}
        actionSheetMenuItems={actionSheetMenuItems}
        avatarCircleSrc={{ uri: avatarImgUrl || undefined }}
        avatarLabel={membership?.displayName || ''}
        avatarSubLabel={dream.place}
        hasTopDivider={true}
        linkObject={linkObject}
        userInfoLinkObject={userInfoLinkObject}
        avatarOnPress={avatarOnPress}
        truncate={true}
        commentCount={dream.commentCount}
        reactionCount={dream.reactionCount}
        userReactionCount={dream.userReactionCount}
        showReactionRow={!dream.isDraft}
        onReactionPress={addOrRemoveReaction}
      />
      {deleteAlertOpen && (
        <AlertPopup
          isOpen={deleteAlertOpen}
          header={t('deleteDreamDialog.title')}
          description={confirmMessage}
          confirmLabel={t('common.delete')}
          onConfirm={() => {
            setDeleteAlertOpen(false)
            dispatch(deleteDream(appUser?.userGroupId || '', dream.id))
          }}
          onClose={() => {
            setDeleteAlertOpen(false)
          }}
        />
      )}
      {unshareAlertOpen && (
        <AlertPopup
          isOpen={unshareAlertOpen}
          header={t('common.unshare')}
          description={t('dialog.unshareDream.description', {
            groupName: currentGroup?.name || '',
          })}
          confirmLabel={t('common.unshare')}
          onConfirm={() => {
            setUnshareAlertOpen(false)
            dispatch(unshareDreamFromGroups(dream.id, [currentGroup.id]))
          }}
          onClose={() => {
            setUnshareAlertOpen(false)
          }}
        />
      )}
    </>
  )
}

export default FeedItemDream
