/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Waypoint } from '../models/Waypoint';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WaypointService {
  /**
   * Creates a Waypoint resource.
   * Creates a Waypoint resource.
   * @param id Waypoint identifier
   * @param requestBody The new Waypoint resource
   * @returns Waypoint Waypoint resource created
   * @throws ApiError
   */
  public static analyzePath(
    id: string,
    requestBody?: Waypoint, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Waypoint> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/paths/{id}/analyze',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves the collection of Waypoint resources.
   * Retrieves the collection of Waypoint resources.
   * @param id Waypoint identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns Waypoint Waypoint collection
   * @throws ApiError
   */
  public static getWaypoints(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<Waypoint>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/paths/{id}/waypoints',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a Waypoint resource.
   * Creates a Waypoint resource.
   * @param id Waypoint identifier
   * @param requestBody The new Waypoint resource
   * @returns Waypoint Waypoint resource created
   * @throws ApiError
   */
  public static createWaypoint(
    id: string,
    requestBody?: Waypoint, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Waypoint> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/paths/{id}/waypoints',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a Waypoint resource.
   * Retrieves a Waypoint resource.
   * @param id Waypoint identifier
   * @param waypointId Waypoint identifier
   * @returns Waypoint Waypoint resource
   * @throws ApiError
   */
  public static getWaypoint(
    id: string,
    waypointId: string,
  ): CancelablePromise<Waypoint> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/paths/{id}/waypoints/{waypointId}',
      path: {
        'id': id,
        'waypointId': waypointId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the Waypoint resource.
   * Replaces the Waypoint resource.
   * @param id Waypoint identifier
   * @param waypointId Waypoint identifier
   * @param requestBody The updated Waypoint resource
   * @returns Waypoint Waypoint resource updated
   * @throws ApiError
   */
  public static updateWaypoint(
    id: string,
    waypointId: string,
    requestBody?: Waypoint, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<Waypoint> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/paths/{id}/waypoints/{waypointId}',
      path: {
        'id': id,
        'waypointId': waypointId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the Waypoint resource.
   * Removes the Waypoint resource.
   * @param id Waypoint identifier
   * @param waypointId Waypoint identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteWaypoint(
    id: string,
    waypointId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/paths/{id}/waypoints/{waypointId}',
      path: {
        'id': id,
        'waypointId': waypointId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
