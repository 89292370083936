import React from 'react'
import { Tabs, TabProps } from '../../layout/tab-view-tabs'
import { StyleSheet, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PurchasesPackage } from '../../../revenuecat'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { TabView } from '../../layout/tab-view'
import { Feature } from './feature'
import LottieView from 'lottie-react-native'
import { FEW } from '../../../i18n/config'
import { cap } from '../../../modules/strings/string-helpers'
import { View } from '../../common/view/view'
import { WEB_MAX_WIDTH } from '../../../constants/constants'

type FeatureOffering = 'free' | 'credits' | 'premium' | 'supporter'
export type FeatureSlide = TabProps<FeatureOffering> & {
  SvgIcon?: ({ color, size }: { color: string; size: number }) => JSX.Element
  key: FeatureOffering
  title?: string
  features: string
  defaultValue?: string
  offerings: PurchasesPackage[]
  name: FeatureOffering
}

type Offerings = {
  premium?: PurchasesPackage[]
  supporter?: PurchasesPackage[]
  credits?: PurchasesPackage[]
}

export const FeatureCarousel = React.memo(
  ({
    offeringsToShow,
    initialTab,
  }: {
    offeringsToShow: string[]
    initialTab?: string
  }) => {
    // Hooks
    const { t } = useTranslation()
    const user = useSelector(selectUser)

    const confettiRef = React.useRef<LottieView>(null)

    function triggerConfetti() {
      confettiRef.current?.play(0)
    }

    // Tab bar layout
    // 1) FREE TAB
    const FreeTabContents = () => (
      <Tabs.ScrollView>
        <Feature feature={'free'} triggerConfetti={triggerConfetti} />
      </Tabs.ScrollView>
    )

    // 2) PREMIUM TAB
    const PremiumTabContents = () => (
      <Tabs.ScrollView>
        <Feature feature={'premium'} triggerConfetti={triggerConfetti} />
      </Tabs.ScrollView>
    )

    // 3) SUPPORTER TAB
    const SupporterTabContents = () => (
      <Tabs.ScrollView>
        <Feature feature={'supporter'} triggerConfetti={triggerConfetti} />
      </Tabs.ScrollView>
    )

    // 4) CREDITS TAB
    const CreditsTabContents = () => (
      <Tabs.ScrollView>
        <Feature feature={'credits'} triggerConfetti={triggerConfetti} />
      </Tabs.ScrollView>
    )

    const tabs: TabProps<string>[] = [
      {
        name: 'free',
        children: <FreeTabContents />,
        label: t('subscriptions.tiers.free.short'),
      },
      {
        name: 'premium',
        children: <PremiumTabContents />,
        label: t('subscriptions.tiers.premium.short'),
      },
      {
        name: 'supporter',
        children: <SupporterTabContents />,
        label: t('subscriptions.tiers.supporter.short'),
      },
      {
        name: 'credits',
        children: <CreditsTabContents />,
        label: cap(t('common.credit_plural', { count: FEW })),
      },
    ].filter((tab) => offeringsToShow.includes(tab.name as FeatureOffering))

    const renderConfetti = () => (
      <View nativeID="confetti" pointerEvents="none" style={styles.lottie}>
        <LottieView
          ref={confettiRef}
          source={require('./celebrate.json')}
          style={styles.lottie}
          resizeMode="cover"
          autoPlay={false}
          loop={false}
        />
      </View>
    )

    return (
      <>
        <View flex={1}>
          <TabView
            hideTabHeader={offeringsToShow.length === 1}
            // paddingBottom={isLibrary ? 0 : undefined}
            paddingBottom={80}
            tabs={tabs}
            scenes={{
              free: FreeTabContents,
              premium: PremiumTabContents,
              supporter: SupporterTabContents,
              credits: CreditsTabContents,
            }}
            initialTabName={
              offeringsToShow.length === 1
                ? undefined
                : initialTab
                ? initialTab
                : (user?.subscriptionTier as string)
            }
          />
          {Platform.OS !== 'web' ? renderConfetti() : null}
        </View>
        {Platform.OS === 'web' ? renderConfetti() : null}
      </>
    )
  },
)

FeatureCarousel.displayName = 'FeatureCarousel'

const styles = StyleSheet.create({
  lottie: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    pointerEvents: 'none',
    maxWidth: WEB_MAX_WIDTH,
    marginHorizontal: 'auto',
    width: '100%',
    backgroundColor: 'transparent',
  },
})
