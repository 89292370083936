import React, { useEffect, useState } from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useTranslation } from 'react-i18next'
import { BodyTextMarkdown } from '../../components/common/copy/text-body-markdown'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { RootStackScreenProps } from '../../navigation/types'
import { Path, PathService } from '../../../../api/_openapi'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { InputAddText } from '../../components/common/inputs/input-add-text'
import { Row } from '../../components/common/row/row'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { BodyText } from '../../components/common/copy/text-body'
import { Box } from '../../components/common/box/box'
import { useNavigation } from '@react-navigation/core'

export default function PrivateDreamerPaths({
  route,
}: RootStackScreenProps<'PrivateDreamerPaths'>) {
  const navigation = useNavigation<any>()
  const [paths, setPaths] = useState<Path[]>([])

  useEffect(() => {
    PathService.getPaths().then((paths) => {
      setPaths(paths)
    })
  }, [])

  console.log('paths', paths)

  const addPath = (topic: string) => {
    PathService.createPath({
      createdAt: '',
      id: '',
      description: topic,
    }).then((path) => {
      setPaths([...paths, path])
    })
  }

  const viewPath = (id: string) => {
    navigation.navigate('PrivateDreamerPath', { id })
  }

  const deletePath = (id: string) => {
    PathService.deletePath(id).then(() => {
      setPaths(paths.filter((path) => path.id !== id))
    })
  }

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea>
        <Box pb={10}>
          {paths.map((path) => {
            return (
              <Box key={path.id} padding={2} w="100%">
                <Row>
                  <HeadingSmall>{path.name}</HeadingSmall>
                </Row>
                <Row>
                  <BodyText>{path.description}</BodyText>
                </Row>
                <Row mt={2}>
                  <ButtonPill
                    buttonSize={'xs'}
                    width={100}
                    mr={2}
                    onPress={() => viewPath(path.id)}
                  >
                    View
                  </ButtonPill>
                  <ButtonPill
                    buttonSize={'xs'}
                    width={100}
                    onPress={() => deletePath(path.id)}
                  >
                    Delete
                  </ButtonPill>
                </Row>
              </Box>
            )
          })}
        </Box>
        <InputAddText
          placeholder={'Add a new path'}
          onSubmit={(topic) => addPath(topic)}
        />
      </PaddedContentArea>
    </ScrollView>
  )
}
