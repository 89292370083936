import { useSelector } from './ducks/root-reducer'
import React, { useEffect } from 'react'
import { useColorMode } from 'native-base'
import { storeHasDreams } from './ducks/dream-tag/dream-tag'
import { fetchFastUserById } from './ducks/user/functions/user-functions'
import { useDispatch } from 'react-redux'
import { selectUser, setUser } from './ducks/user/user'
import { useAuth } from './contexts/auth-context'
import { Platform } from 'react-native'
import {
  loadArtStyles,
  loadFamousUsers,
  loadFeatureMap,
  loadInterpretationStyles,
  loadSystemTags,
} from './ducks/ui/thunks/ui-thunks'
import { getDeviceLanguageCode } from './modules/language-helpers/language-helpers'
import {
  loadUserProfileAndGroups,
  updateUser,
} from './ducks/user/thunks/user-thunks'
import { loadFriends } from './ducks/friends/thunks/friend-thunks'
import { initialLoadUserDreams } from './ducks/dream-tag/thunks/dream-thunks'
import { navigationRef } from './navigation/RootNavigation'
import i18n from './i18n/i18nnext'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { FastUserService } from '../../api/_openapi'

export function UserInit({ children }: any) {
  const { setColorMode } = useColorMode()
  const hasDreams = useSelector(storeHasDreams)

  const dispatch = useDispatch<any>()
  const { signOut, authData } = useAuth()
  const user = useSelector(selectUser)

  // VARS
  // Get src from local storage, if it's there
  // (if web)
  let src: string | null = ''
  if (Platform.OS === 'web') {
    src = localStorage.getItem('src')
  }

  // EFFECTS
  // 1) LOAD USER
  useEffect(() => {
    async function loadUser(userId: string) {
      try {
        const user = await fetchFastUserById(userId)
        if (user) {
          dispatch(setUser(user))
        } else {
          throw new Error('User not found')
        }
      } catch (error) {
        console.error(error)
        await signOut()
      }
    }

    if (authData?.user) {
      loadUser(authData?.user)
    }
  }, [authData?.user])

  // If we have a src, and the user doesn't have a source field
  // Update the user with the source
  useEffect(() => {
    if (src && user && !user.source) {
      FastUserService.fastUserUpdate(user.id, {
        source: src || 'unknown',
      })
        .then((res) => {
          console.log('Updated user with source', res)
        })
        .catch((err) => {
          console.log('Error updating user with source', err)
        })
    }
  }, [src, user])

  // LANGUAGE
  useEffect(() => {
    if (!user || !user.languageCode) {
      return
    }
    const deviceLanguage = getDeviceLanguageCode()
    const language = user?.languageCode || deviceLanguage
    async function changeLanguage(language: any) {
      try {
        await i18n.changeLanguage(language)
      } catch (error) {
        console.log('error setting language', error)
      }
    }
    changeLanguage(language)
  }, [user?.languageCode])

  // LOAD USER DATA
  useEffect(() => {
    if (!user || !authData?.token) {
      return
    }

    // Set the timezone offset
    const offset = new Date().getTimezoneOffset()
    if (offset !== user.timezoneOffset) {
      dispatch(
        updateUser(
          user.id,
          {
            timezoneOffset: offset,
          },
          false,
        ),
      )
    }

    dispatch(loadUserProfileAndGroups(user))
    dispatch(loadFriends())

    // Load dreams
    if (user && user.userGroupId && authData?.user && !hasDreams) {
      console.log('Fetching dreams from server')
      dispatch(initialLoadUserDreams(user.userGroupId))
    }

    if (authData?.token) {
      dispatch(loadSystemTags())
      dispatch(loadArtStyles())
      dispatch(loadInterpretationStyles())
      dispatch(loadFeatureMap(authData.token))
      dispatch(loadFamousUsers())
    }
  }, [user?.id])

  useEffect(() => {
    async function setAppColorMode() {
      try {
        const currentValue = await AsyncStorage.getItem('@ColorMode')
        if (
          currentValue !== user?.colorMode &&
          navigationRef?.current?.getCurrentRoute()?.name !==
            'PrivateDreamerSettingsEdit'
        ) {
          setColorMode(user?.colorMode || 'auto')
        }
      } catch (error) {
        console.log('error setting color mode', error)
      }
    }
    setAppColorMode()
  }, [user?.colorMode])

  return children
}
