import React from 'react'
import AvatarBauhaus from './avatar-bauhaus'
import AvatarMarble from './avatar-marble'
import type { BoringAvatarProps } from '../types'

const variants = ['bauhaus', 'marble'] as const

function getAvatar(
  avatarType: (typeof variants)[number],
  avatarProps: BoringAvatarProps,
): any {
  const avatars: any = {
    bauhaus: () => <AvatarBauhaus {...avatarProps} />,
    marble: () => <AvatarMarble {...avatarProps} />,
  }
  return avatars[avatarType]()
}

const Avatar = ({
  variant = 'marble',
  colors = ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'],
  name = 'Sacagawea',
  square = false,
  size = 40,
  ...props
}) => {
  const avatarProps = { colors, name, size, square, ...props }
  const checkedVariant = () => {
    if (variants.includes(variant as (typeof variants)[number])) {
      return variant
    }
    return 'marble'
  }

  const avatarComponent = getAvatar(
    checkedVariant() as (typeof variants)[number],
    avatarProps,
  )

  return avatarComponent
}

export default Avatar
