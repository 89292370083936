import React from 'react'
import { useAuth } from '../../../contexts/auth-context'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { useTranslation } from 'react-i18next'
import { SettingsSection } from '../../../../../api/frontend-types'
import { SettingsList } from '../../../components/composite/settings/settings'
import { IconMoon } from '../../../assets/react-native-svg/icons/icons-tag'
import {
  IconCoin,
  IconEdit,
  IconExport,
  IconInfo,
  IconNotifications,
  IconGem,
  IconSettings,
  IconSignout,
  IconTrash,
  IconUserSettings,
  IconRefresh,
  IconHelp,
  IconImport,
} from '../../../assets/react-native-svg/icons/icons-ui'
import { Purchases } from '../../../revenuecat'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useDrawer } from '../../../contexts/drawer-context'
import { showShake } from './shake-helper'

export type SettingsMainNavigationProp =
  RootStackNavigationProp<'PrivateSettings'>

export default function SettingsMain({
  navigation,
  route,
}: RootStackScreenProps<'PrivateSettings'>) {
  const { signOut } = useAuth()
  const { setDrawerVisible } = useDrawer()
  let { t } = useTranslation()

  const sections: SettingsSection[] = [
    {
      title: t('settings.subscriptions.heading'),
      minTier: 'free', // Only show this section if the user is at least this tier
      hideOnWeb: false,
      items: [
        {
          label: t('settings.subscriptions.aboutSubscriptions'),
          subLabel: t('settings.subscriptions.aboutSubscriptions.subHeading'),
          icon: IconInfo,
          internalLink: 'PrivateSubscriptionStatusView',
        },
        {
          label: t('settings.subscriptions.manageSubscription'),
          subLabel: t('settings.subscriptions.manageSubscription.subHeading'),
          icon: IconGem,
          internalLink: 'PrivateSubscriptionSettingsEdit',
        },
        {
          label: t('settings.subscriptions.credits'),
          subLabel: t('settings.subscriptions.credits.subHeading'),
          icon: IconCoin,
          internalLink: 'PrivateCreditSettingsEdit',
        },
        {
          label: t('settings.subscriptions.restorePurchases'),
          subLabel: t('settings.subscriptions.restorePurchases.subHeading'),
          icon: IconRefresh,
          onPress: async () => {
            try {
              await Purchases.restorePurchases()
              elsewhereToast.showToast({
                description: t('toast.restorePurchases.success'),
                status: 'success',
              })
            } catch (e) {
              console.log(e)
              elsewhereToast.showToast({
                title: t('toast.restorePurchases.error.title'),
                description: t('toast.restorePurchases.error.description'),
                status: 'error',
                duration: null,
                id: 'restorePurchasesError',
              })
            }
          },
          hideOnWeb: true, // Presumably this wouldn't work on web
        },
      ],
    },
    {
      title: t('settings.appSettings'),
      items: [
        {
          label: t('settings.userSettings'),
          subLabel: t('settings.userSettings.subHeading'),
          icon: IconUserSettings,
          internalLink: 'PrivateDreamerSettingsEdit',
        },
        {
          label: t('settings.dreamSettings'),
          subLabel: t('settings.dreamSettings.subHeading'),
          icon: IconMoon,
          internalLink: 'PrivateDreamSettingsEdit',
        },
        {
          label: t('settings.notificationSettings'),
          subLabel: t('settings.notificationSettings.subHeading'),
          icon: IconNotifications,
          internalLink: 'PrivateNotificationsSettingsEdit',
        },
      ],
    },
    {
      title: t('common.profile'),
      items: [
        {
          label: t('settings.editProfile'),
          subLabel: t('settings.editProfile.subHeading'),
          icon: IconEdit,
          internalLink: 'PrivateDreamerEdit',
        },
        {
          label: t('settings.editPersonalInfo'),
          subLabel: t('settings.editPersonalInfo.subHeading'),
          icon: IconSettings,
          internalLink: 'PrivateDreamerProfileEdit',
        },
      ],
    },
    {
      title: t('settings.yourData'),
      items: [
        {
          label: t('settings.exportData'),
          subLabel: t('settings.exportData.subHeading'),
          icon: IconExport,
          internalLink: 'PrivateDataExport',
        },
        {
          label: t('settings.importData.heading'),
          subLabel: t('settings.importData.description'),
          icon: IconImport,
          internalLink: 'PrivateDataImport',
          hideOnMobile: true,
        },
        {
          label: t('settings.deleteData'),
          subLabel: t('settings.deleteData.subHeading'),
          icon: IconTrash,
          internalLink: 'PrivateDataDelete',
        },
      ],
    },
    {
      title: t('settings.support'),
      items: [
        {
          label: t('settings.support'),
          subLabel: t('settings.support.subHeading'),
          icon: IconHelp,
          onPress: async () => {
            showShake()
          },
        },
      ],
    },
    {
      title: t('settings.account'),
      items: [
        {
          label: t('settings.signOut'),
          icon: IconSignout,
          onPress: async () => {
            await signOut()
            setDrawerVisible(false)
          },
        },
      ],
    },
  ]

  return (
    <SettingsList sections={sections} navigation={navigation} route={route} />
  )
}
