import React, { useState } from 'react'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { MenuLink } from './menu-link'
import { findFocusedRoute, useNavigationState } from '@react-navigation/core'
import { HeadingSans } from '../../common/copy/heading-sans'
import { useSelector } from '../../../ducks/root-reducer'
import {
  selectIsElsewhereTeam,
  selectUser,
  selectUserLanguage,
} from '../../../ducks/user/user'
import { elsewhereToast } from '../../common/toast/toast'
import { LINE_WIDTH, MAX_GROUPS } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'
import { AvatarCircleMedia } from '../../common/avatar/avatar-circle-media'
import {
  selectGroupById,
  selectUserGroups,
  selectUserMembershipInGroup,
} from '../../../ducks/groups/groups'
import { FEW } from '../../../i18n/config'
import {
  cap,
  capitalize,
  i18nNumber,
} from '../../../modules/strings/string-helpers'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { DialogGroupCode } from '../../common/dialog/dialog-group-code'
import {
  MenuItem,
  SubscriptionTierKey,
} from '../../../../../api/frontend-types'
import { useSafeArea, Divider, useColorModeValue } from 'native-base'
import { Platform, ScrollView } from 'react-native'
import {
  isAtLeastTier,
  isAtMostTier,
} from '../../../modules/subscriptions/subscription-helpers'
import { selectFastDraftDreams } from '../../../ducks/dream-tag/dream-tag'
import {
  MainStackParamList,
  RootStackScreenProps,
} from '../../../navigation/types'
import { useAuth } from '../../../contexts/auth-context'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import {
  selectFriendByGroupId,
  selectFriends,
} from '../../../ducks/friends/friends'
import { DialogUnfriend } from '../../common/dialog/dialog-unfriend'
import { useUserDreams } from '../../../hooks/useUserDreams'
import { useGroupDreams } from '../../../hooks/useGroupDreams'
import { Row } from '../../common/row/row'
import { Box } from '../../common/box/box'

type DrawerContentProps = RootStackScreenProps<'MainTabs'> & {
  drawerWidth: number
  closeDrawer: () => void
}

const GROUPS_STRIP_WIDTH = 77

export const DrawerContent = React.memo(
  ({
    navigation,
    route,
    drawerWidth = 300,
    closeDrawer,
  }: DrawerContentProps) => {
    // STATE
    const [codeDialogOpen, setCodeDialogOpen] = useState<boolean>(false)
    const [unfriendDialogOpen, setUnfriendDialogOpen] = useState<boolean>(false)
    const friends = useSelector(selectFriends)

    const friendImages: { [key: string]: string | null } = friends.reduce(
      (acc, friend) => {
        if (friend.groupId) {
          acc[friend.groupId] = friend.imageUrl
        }
        return acc
      },
      {} as Record<string, string | null>,
    )

    // HOOKS
    let { t } = useTranslation()
    const { signOut } = useAuth()
    const lineColor = useColorModeValue(BLACK, WHITE)

    // SELECTORS
    const user = useSelector(selectUser)
    const joinedGroups = useSelector(selectUserGroups)
    const drafts = useSelector(selectFastDraftDreams)
    const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
    const userLanguage = useSelector(selectUserLanguage)

    // NAV VARS
    const navState = useNavigationState((state) => state)
    const currentRoute: any = findFocusedRoute(navState)
    const activeGroupId = useActiveGroupId()
    const friend = useSelector(selectFriendByGroupId(activeGroupId))

    const membershipInfo = useSelector(
      selectUserMembershipInGroup(user?.id, activeGroupId),
    )
    const currentGroup = useSelector(selectGroupById(activeGroupId))
    const favoriteDreams = useUserDreams({
      favorites: true,
    })
    const favoriteGroupDreams = useGroupDreams(currentGroup?.id, {
      favorites: true,
    })

    // VARS
    const numDrafts = drafts.length
    const userIsGroupOwner = membershipInfo && membershipInfo.role === 'owner'
    const isUserPrivateGroup = currentGroup?.type === 'user'
    const imageUrl = isUserPrivateGroup
      ? user?.profileImageUrl
      : currentGroup?.imageUrl
    const isWeb = Platform.OS === 'web'
    const userTier: SubscriptionTierKey | undefined =
      user?.subscriptionTier as SubscriptionTierKey
    const canJoinGroups = joinedGroups.length < MAX_GROUPS || isElsewhereTeam

    const getChannel = (routeName: keyof MainStackParamList) => {
      if (!route) return 'journal'

      const screens: {
        [key in keyof MainStackParamList]:
          | 'journal'
          | 'drafts'
          | 'diary'
          | 'insights'
          | 'ask'
          | 'charts'
          | 'search'
          | 'symbols'
          | 'friends'
          | 'interpretation'
          | 'dashboard'
          | 'friend'
          | 'friendship'
          | 'shared-dreams'
          | 'bookmarks'
          | 'liked-dreams'
          | 'dream-services'
          | 'dream-service'
      } = {
        PrivateFeed: 'journal',
        GroupFeed: 'journal',
        PrivateTagView: 'journal',
        PrivateSymbolInterpretationView: 'journal',
        PrivateDreamView: 'journal',
        GroupDreamView: 'journal',
        PrivateDraftsFeed: 'drafts',
        PrivateDraftView: 'drafts',
        PrivateInsights: 'insights',
        PrivateInsightList: 'insights',
        PrivateInsightView: 'insights',
        PrivateAskYourDreams: 'ask',
        PrivateAskYourDreamsInsightView: 'ask',
        PrivateCharts: 'charts',
        PrivateSearch: 'search',
        PrivateSymbols: 'symbols',
        PrivateFriends: 'friends',
        PrivateInterpretationView: 'interpretation',
        PrivateDashboard: 'dashboard',
        PrivateFriend: 'friend',
        PrivateFriendSharedDreams: 'shared-dreams',
        PrivateDiaryFeed: 'diary',
        PrivateDiaryView: 'diary',
        PrivateFavoritesFeed: 'bookmarks',
        GroupFavoritesFeed: 'liked-dreams',
        PrivateDreamServicesView: 'dream-services',
        PrivateDreamServiceView: 'dream-service',
      }

      return screens[routeName] || 'journal'
    }
    const selectedChannel = getChannel(currentRoute.name)

    let menuItems: MenuItem[] = [
      {
        id: 'dashboard',
        label: friend ? t('Overview') : t('common.dashboard'),
        showInGroup: ['friend', 'user'].includes(currentGroup?.type || ''),
        showInUser: true,
        showOnWeb: true,
        showOnMobile: false,
        hideFromNonTeam: true,
        minTier: 'supporter',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateDashboard' },
          })

          if (isUserPrivateGroup) {
            navigation.navigate('MainTabs', {
              screen: 'MainStack',
              params: { screen: 'PrivateDashboard' },
            })
          } else if (friend) {
            navigation.navigate('MainTabs', {
              screen: 'MainStack',
              params: {
                screen: 'PrivateFriend',
                params: {
                  username: friend.username,
                },
              },
            })
          }
          closeDrawer()
        },
        isSelected: selectedChannel === 'friend',
      },
      {
        id: 'journal',
        label: t('common.journal'),
        showInGroup: true,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          isUserPrivateGroup
            ? navigation.navigate('MainTabs', {
                screen: 'MainStack',
                params: { screen: 'PrivateFeed' },
              })
            : navigation.navigate('MainTabs', {
                screen: 'MainStack',
                params: {
                  screen: 'GroupFeed',
                  params: {
                    groupId: currentGroup?.id as string,
                  },
                },
              })
          closeDrawer()
        },
        isSelected: selectedChannel === 'journal',
      },
      {
        id: 'shared-dreams',
        label: 'Shared Dreams',
        showInGroup: !!friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: false,
        hideFromNonTeam: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: {
              screen: 'PrivateFriendSharedDreams',
              params: {
                username: friend?.username as string,
              },
            },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'shared-dreams',
      },
      {
        id: 'in-your-dreams',
        label: 'In your dreams...',
        showInGroup: Boolean(friend && friend?.friendTagId),
        showInUser: false,
        showOnWeb: true,
        showOnMobile: false,
        hideFromNonTeam: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: {
              screen: 'PrivateTagView',
              params: {
                id: friend?.friendTagId as string,
              },
            },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'friendship',
      },
      {
        id: 'diary',
        label: cap(t('common.diary')),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateDiaryFeed' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'diary',
      },
      {
        id: 'paths',
        label: cap(t('Paths')),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        hideFromNonTeam: true,
        minTier: 'premium',
        onPress: () => {
          navigation.navigate('PrivateDreamerPaths')
          closeDrawer()
        },
      },
      {
        id: 'drafts',
        label: cap(t('common.draft_plural', { count: FEW })),
        badgeLabel: numDrafts ? i18nNumber(numDrafts, userLanguage) : undefined,
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateDraftsFeed' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'drafts',
      },
      {
        id: 'bookmarkedDreams',
        label: t('common.bookmarks'),
        badgeLabel: favoriteDreams.dreams.length
          ? i18nNumber(favoriteDreams.dreams.length, userLanguage)
          : undefined,
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: {
              screen: 'PrivateFavoritesFeed',
            },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'bookmarks',
      },
      {
        id: 'likedDreams',
        label: t('common.likedDreams'),
        badgeLabel: favoriteGroupDreams.dreams.length
          ? favoriteGroupDreams.dreams.length.toString()
          : undefined,
        showInGroup: true,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: {
              screen: 'GroupFavoritesFeed',
              params: {
                groupId: currentGroup?.id as string,
              },
            },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'liked-dreams',
      },
      {
        id: 'insights',
        label: capitalize(t('common.insight_plural', { count: FEW })),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateInsights' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'insights',
      },
      {
        id: 'askYourDreams',
        label: t('askYourDreams.title'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateAskYourDreams' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'ask',
      },
      {
        id: 'charts',
        label: t('common.charts'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateCharts' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'charts',
      },
      {
        id: 'search',
        label: t('common.search'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateSearch' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'search',
      },
      {
        id: 'symbols',
        label: t('common.mySymbols'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateSymbols' },
          })
          closeDrawer()
        },
        isSelected: selectedChannel === 'symbols',
      },
      {
        id: 'friends',
        label: t('common.friends'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,
        hideFromNonTeam: true,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('MainTabs', {
            screen: 'MainStack',
            params: { screen: 'PrivateFriends' },
          })
          closeDrawer()
        },
      },
      {
        id: 'groupProfile',
        label: t('common.groupProfile'),
        showInGroup: !friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => {
          navigation.navigate('GroupProfileView', {
            groupId: currentGroup?.id as string,
          })
        },
      },
      {
        id: 'library',
        label: t('common.library'),
        showInGroup: false,
        showInUser: false,
        showOnWeb: false,
        showOnMobile: false,
        minTier: 'free',
        onPress: () => {
          navigation.navigate('Library')
          closeDrawer()
        },
      },
      {
        id: 'settings',
        label: t('common.settings'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => navigation.navigate('PrivateSettings'),
      },
      {
        id: 'groupMembers',
        label: cap(t('common.member_plural', { count: FEW })),
        showInGroup: !friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => {
          navigation.navigate('GroupMembersView', {
            groupId: currentGroup?.id as string,
          })
        },
      },
      {
        id: 'inviteMembers',
        label: t('common.inviteMembers'),
        showInGroup: !friend,
        showInUser: false,
        forOwnersOnly: true,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => {
          setCodeDialogOpen(true)
        },
      },
      {
        id: 'groupSettings',
        label: t('common.groupSettings'),
        showInGroup: !friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () =>
          navigation.navigate('GroupSettings', {
            groupId: currentGroup?.id as string,
          }),
      },
      {
        id: 'friendPreferences',
        label: t('Preferences'),
        showInGroup: !!friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,
        minTier: 'free',
        onPress: () =>
          navigation.navigate('PrivateFriendPreferences', {
            username: friend?.username as string,
          }),
      },
      {
        id: 'unfriend',
        label: t('friends.unfriend'),
        showInGroup: !!friend,
        showInUser: false,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => setUnfriendDialogOpen(true),
      },
      {
        id: 'aboutElsewhere',
        label: t('common.aboutElsewhere'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,

        minTier: 'free',
        onPress: () => navigation.navigate('PrivateAboutElsewhere'),
      },
      {
        id: 'subscriptions',
        label: t('common.subscribe'),
        showInGroup: false,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,

        maxTier: 'free',
        onPress: () =>
          navigation.navigate('PrivateSubscriptionSettingsEdit', {
            tab: 'premium',
          }),
      },
      {
        id: 'signOut',
        label: t('settings.signOut'),
        showInGroup: true,
        showInUser: true,
        showOnWeb: true,
        showOnMobile: true,

        maxTier: 'free',
        onPress: async () => {
          await signOut()
        },
      },
    ]
    menuItems = menuItems.filter((item) => {
      return item.id !== 'diary' || isElsewhereTeam
    })

    // Need this to get safe area padding on top
    // Not sure if really needed
    const safeAreaProps = useSafeArea({
      safeAreaTop: true,
    })

    // User's group should be first in the list
    // Other groups are sorted by date created
    // Later we can sort by most recent activity?

    const privateGroup = joinedGroups.find((g) => g.type === 'user')
    const sortedGroups = joinedGroups
      .filter((g) => g.name !== 'Public')
      .filter((g) => g.type !== 'user')
      .sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
      .filter((g) => Boolean(g))

    if (privateGroup) {
      sortedGroups.unshift(privateGroup)
    }

    let activeGroupName: string | undefined = ''
    if (isUserPrivateGroup) {
      activeGroupName = user?.username
    } else if (friend) {
      activeGroupName = friend.friendName || friend.username
    } else {
      activeGroupName = currentGroup?.name
    }
    if (!activeGroupName) {
      activeGroupName = ''
    }

    return (
      <>
        <Box flex={1}>
          <Box {...safeAreaProps} style={{ flex: 1, height: '100%' }}>
            <Row
              w="100%"
              h="100%"
              divider={<Divider thickness={LINE_WIDTH} bg={lineColor} />}
            >
              <ScrollView>
                <Box alignItems={'center'} w={GROUPS_STRIP_WIDTH} pb={2}>
                  {sortedGroups
                    .map((g) => {
                      const isSelected = g.id === currentGroup?.id
                      const isUserGroup = g.type === 'user'
                      const imageUrl = isUserGroup
                        ? user?.profileImageUrl
                        : g.imageUrl
                      const avatarUrl =
                        friendImages[g.id] || imageUrl || undefined
                      return (
                        <Box
                          key={g.id}
                          mt={isUserGroup && isWeb ? 2 : 0}
                          borderBottomColor={
                            isUserGroup ? lineColor : 'transparent'
                          }
                          borderBottomWidth={LINE_WIDTH}
                          paddingBottom={isUserGroup ? 2 : 0}
                          marginBottom={isUserGroup ? 2 : 0}
                        >
                          {/* GROUP AVATAR CIRCLE */}
                          <AvatarCircleMedia
                            key={g.id}
                            source={avatarUrl}
                            mb={1}
                            size={'md'}
                            onPress={
                              isSelected
                                ? undefined
                                : () => {
                                    if (isUserGroup) {
                                      navigation.navigate('MainTabs', {
                                        screen: 'MainStack',
                                        params: {
                                          screen: 'PrivateFeed',
                                        },
                                      })
                                    } else {
                                      if (g.type === 'friend') {
                                        navigation.navigate('MainTabs', {
                                          screen: 'MainStack',
                                          params: {
                                            screen: 'PrivateFriend',
                                            params: {
                                              username: 'agoodfriend',
                                            },
                                          },
                                        })
                                      } else {
                                        navigation.navigate('MainTabs', {
                                          screen: 'MainStack',
                                          params: {
                                            screen: 'GroupFeed',
                                            params: {
                                              groupId: g.id,
                                            },
                                          },
                                        })
                                      }
                                    }
                                  }
                            }
                            selected={isSelected}
                            disablePress={isSelected}
                            name={
                              isUserGroup
                                ? (user?.username as string)
                                : (g.name as string)
                            }
                          />
                        </Box>
                      )
                    })
                    .concat(
                      <ButtonIcon
                        key={'add-group'}
                        size="md"
                        variant={'outlined'}
                        iconKey={'plus'}
                        mt={2}
                        onPress={() => {
                          if (canJoinGroups) {
                            navigation.navigate('GroupAdd')
                          } else {
                            elsewhereToast.showToast({
                              description: t(
                                'toast.maxGroupsWarning.description',
                                {
                                  maxGroups: MAX_GROUPS,
                                },
                              ),
                              status: 'warning',
                            })
                          }
                        }}
                      />,
                    )}
                </Box>
              </ScrollView>
              <Box w={drawerWidth - GROUPS_STRIP_WIDTH} alignItems={'center'}>
                <Box
                  borderBottomWidth={LINE_WIDTH}
                  borderBottomColor={lineColor}
                  w="90%"
                  alignItems={'center'}
                >
                  <Box alignItems={'center'} key={currentGroup?.id}>
                    {/* User avatar circle */}
                    <AvatarCircleMedia
                      source={
                        friendImages[currentGroup?.id || ''] ||
                        imageUrl ||
                        undefined
                      }
                      mt={4}
                      mb={2}
                      size={'xl'}
                      name={activeGroupName}
                      onPress={() => {
                        if (isUserPrivateGroup) {
                          navigation.navigate('PrivateDreamerView', {
                            userId: user?.id as string,
                          })
                        } else {
                          navigation.navigate('GroupProfileView', {
                            groupId: currentGroup?.id as string,
                          })
                        }
                      }}
                      showTier={isUserPrivateGroup}
                      userTier={userTier}
                    />
                    <HeadingSans
                      mt={2}
                      mb={4}
                      language="en" // So we use a font with English/ASCII characters
                    >
                      {activeGroupName}
                    </HeadingSans>
                  </Box>
                </Box>
                <ScrollView
                  style={{
                    width: drawerWidth - GROUPS_STRIP_WIDTH,
                  }}
                >
                  <Box
                    w={drawerWidth - GROUPS_STRIP_WIDTH}
                    mb={10}
                    paddingTop={4}
                    flexGrow={1}
                    h="100%"
                  >
                    {menuItems
                      //  Filter for group access
                      .filter((menuItem) => {
                        if (isUserPrivateGroup) {
                          return menuItem.showInUser
                        } else {
                          // Only show items the user has permission to access
                          const canAccess =
                            !menuItem.forOwnersOnly || userIsGroupOwner
                          return menuItem.showInGroup && canAccess
                        }
                      })
                      // Filter for web / mobile visibility
                      .filter((menuItem) => {
                        const passesWebCheck = !isWeb || menuItem.showOnWeb
                        const passesMobileCheck = isWeb || menuItem.showOnMobile
                        return passesWebCheck && passesMobileCheck
                      })
                      // Filter for team visibility
                      .filter((menuItem) => {
                        if (isElsewhereTeam) {
                          return true
                        }
                        return !menuItem.hideFromNonTeam
                      })
                      // Filter based on user tier
                      .filter((i) => {
                        const passesMinTierRequirement = isAtLeastTier(
                          userTier,
                          i.minTier,
                        )
                        const passesMaxTierRequirement = isAtMostTier(
                          userTier,
                          i.maxTier,
                        )
                        return (
                          passesMinTierRequirement && passesMaxTierRequirement
                        )
                      })
                      // Only show signout if privateGroupId is unset
                      // This is likely an error state
                      // and we want to make it recoverable
                      .filter(
                        (menuItem) =>
                          menuItem.id !== 'signOut' ||
                          privateGroup?.id === undefined,
                      )
                      .map((menuItem, i) => {
                        return (
                          <MenuLink
                            key={menuItem.id}
                            onPress={menuItem.onPress}
                            width={
                              drawerWidth -
                              GROUPS_STRIP_WIDTH -
                              (isWeb ? 10 : 2)
                            }
                            badgeLabel={menuItem.badgeLabel}
                            isSelected={menuItem.isSelected}
                          >
                            {menuItem.label}
                          </MenuLink>
                        )
                      })}
                  </Box>
                </ScrollView>
              </Box>
            </Row>
          </Box>
        </Box>
        <DialogGroupCode
          isOpen={codeDialogOpen}
          groupId={activeGroupId || ''}
          onClose={() => setCodeDialogOpen(false)}
        />
        <DialogUnfriend
          isOpen={unfriendDialogOpen}
          friend={friend}
          onClose={() => setUnfriendDialogOpen(false)}
        />
      </>
    )
  },
)
