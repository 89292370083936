import React, { memo } from 'react'
import { Svg } from 'react-native-svg'
import { avatarAndIconButtonSizes, Size } from '../../../core/theme'
import { ImageURISource } from 'react-native'
import BoringAvatar from '../../../modules/react-native-boring-avatars'
import { Loading } from '../../layout/loading'
import { LINE_WIDTH } from '../../../constants/constants'
import { SvgUrl } from '../svg/svg-url'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
  LIGHT_GRAY,
  DARK_MID_GRAY,
  LIGHT_MID_GRAY,
  DARK_GRAY,
  DARK_CREAM,
  LIGHTER_BLACK,
  GOLD,
  SILVER,
  DARKMODE_GOLD,
  DARKMODE_SILVER,
} from '../../../constants/ui-constants'
import { Avatar, IAvatarProps, useColorModeValue } from 'native-base'
import {
  IconPublic,
  PublicSvgGroup,
} from '../../../assets/react-native-svg/icons/icons'
import { IconGem } from '../../../assets/react-native-svg/icons/icons-ui'
import { SubscriptionTierKey } from '../../../../../api/frontend-types'
import { Box } from '../box/box'
import { Center } from '../center/center'
import { Button } from '../nb-button/nb-button'
import { IconProps } from '../icon/icon'

type BadgeIcon = 'public' | 'user' | 'group'

// Standard https://docs.nativebase.io/avatar props
// Plus a few extras
export type AvatarCircleProps = IAvatarProps & {
  name: string
  size?: Size
  selected?: boolean
  online?: boolean
  badgeIcon?: BadgeIcon
  onPress?: () => void
  showTier?: boolean
  userTier?: SubscriptionTierKey
  source?: ImageURISource
  loading?: boolean
  disablePress?: boolean
}

const iconMap: {
  [key in BadgeIcon]: (props: IconProps) => JSX.Element
} = {
  public: IconPublic,
  user: IconPublic,
  group: IconPublic,
}

export const AvatarCircle = memo(
  ({
    selected,
    online,
    badgeIcon,
    name,
    onPress,
    mb,
    mt,
    mr,
    ml,
    size,
    showTier = false,
    userTier = 'free',
    source,
    loading,
    disablePress,
    ...rest
  }: AvatarCircleProps) => {
    // VARS
    const Icon = badgeIcon ? iconMap[badgeIcon] : null
    const avatarSize: Size = size || 'md'
    const buttonSize = avatarAndIconButtonSizes[avatarSize].width + 3
    const boringAvatarSize = avatarAndIconButtonSizes[avatarSize].width * 4

    // HOOKS
    const color = useColorModeValue(BLACK, WHITE)
    const borderColor = useColorModeValue(BLACK, WHITE)
    const backgroundColor = useColorModeValue(WHITE, BLACK)
    const gold = useColorModeValue(GOLD, DARKMODE_GOLD)
    const silver = useColorModeValue(SILVER, DARKMODE_SILVER)

    const badgeBgColor =
      userTier === 'supporter'
        ? gold
        : userTier === 'premium'
        ? silver
        : 'transparent'
    const badgeBorderColor = useColorModeValue(DARK_CREAM, LIGHTER_BLACK)

    return loading ? (
      <Loading size={avatarSize} />
    ) : (
      <Button
        style={{
          borderColor: selected ? borderColor : 'transparent',
          borderWidth: LINE_WIDTH,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: selected ? 9999 : undefined,
        }}
        mb={mb}
        mt={mt}
        mr={mr}
        ml={ml}
        // Because the theme sizes are in increments of 4px 🤷
        width={`${buttonSize * 4}px`} //
        height={`${buttonSize * 4}px`}
        variant="unstyled"
        onPress={onPress}
        _pressed={{
          opacity: onPress ? 0.5 : 1,
        }}
        _web={{
          // @ts-ignore
          cursor: disablePress ? 'default' : 'pointer',
        }}
        padding={1}
      >
        {!source || !source.uri ? (
          <BoringAvatar
            size={boringAvatarSize}
            name={name}
            variant="marble"
            colors={[
              CREAM,
              LIGHT_BLACK,
              DARK_MID_GRAY,
              LIGHT_MID_GRAY,
              LIGHT_GRAY,
              DARK_GRAY,
            ]}
          />
        ) : source?.uri?.split('.').at(-1) !== 'svg' ? (
          <Avatar
            {...rest}
            source={source ? source : undefined}
            size={avatarSize}
          >
            {Icon && (
              <Avatar.Badge bg={color} color={backgroundColor}>
                <Svg
                  style={{ margin: 2 }}
                  width="20" // The actual size of the icon
                  height="20"
                  viewBox="0 0 32 32" // The viewbox of the icon, based on vector grid (ours are 32x32)
                  fill={backgroundColor}
                  stroke={backgroundColor}
                >
                  <PublicSvgGroup />
                </Svg>
              </Avatar.Badge>
            )}
          </Avatar>
        ) : (
          <Center
            borderColor="white.500"
            borderWidth={4}
            h={avatarSize}
            w={avatarSize}
          >
            <SvgUrl
              url={source?.uri}
              width={buttonSize * 2}
              height={buttonSize * 2}
              imageWidth={buttonSize * 2}
              imageHeight={buttonSize * 2}
              viewBox={'0 0 2048 2048'}
              fill={color}
            />
          </Center>
        )}
        {userTier !== 'free' && showTier && (
          <Box
            m={0}
            p={0}
            bg={badgeBgColor}
            borderColor={badgeBorderColor}
            borderWidth={LINE_WIDTH}
            rounded="full"
            mt={-8}
            zIndex={1}
            alignSelf="flex-end"
            _text={{
              fontSize: 12,
            }}
            h={'30px'}
            w={'30px'}
          >
            <Center>
              <IconGem
                m={0}
                mt={'6.5px'}
                p={0}
                size={'15px'}
                color={backgroundColor}
              />
            </Center>
          </Box>
        )}
      </Button>
    )
  },
)
