import Shake, { ShakeButtonStyle } from '@shakebugs/browser'
import { selectUser } from './ducks/user/user'
import { useSelector } from './ducks/root-reducer'
import { useEffect } from 'react'
const API_KEY = process.env.REACT_APP_SHAKE_WEB_API_KEY as string

export function ShakeInit({ children }: any) {
  const user = useSelector(selectUser)
  if (user) {
    Shake.config.floatingButtonEnabled = false
    Shake.start(API_KEY)
  }
  useEffect(() => {
    if (user?.id) {
      Shake.registerUser(user.id)
      Shake.updateUserMetadata({
        first_name: user.username,
      })
    }
  }, [user?.id])
  return children
}
