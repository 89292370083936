import React, { useEffect, useState } from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useTranslation } from 'react-i18next'
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigation/types'
import {
  FastDream,
  FastDreamService,
  Path,
  PathService,
  Waypoint,
  WaypointService,
} from '../../../../api/_openapi'
import { BodyText } from '../../components/common/copy/text-body'
import { Loading } from '../../components/layout/loading'
import { TabProps, Tabs } from '../../components/layout/tab-view-tabs'
import { Dimensions, ListRenderItem, Platform, View } from 'react-native'
import { EmptyFeedPage } from '../../components/composite/feed/page-empty-feed'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import FeedItemDream from '../../components/composite/feed/feed-item-dream'
import { RouteProp } from '@react-navigation/core'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { capitalize } from '../../modules/strings/string-helpers'
import { FEW } from '../../i18n/config'
import { TabView } from '../../components/layout/tab-view'
import { TagPageHeader } from '../TagView/header'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import TagViewImage from '../TagView/tag-view-image'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { UserTagDescription } from '../TagView/user-tag-description'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import DreamMap from '../Dreams/DreamMap/DreamMap'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { Center } from '../../components/common/center/center'
import { BLACK, CREAM, LIGHT_BLACK, WHITE } from '../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { TextBadge } from '../../components/common/text-badge/text-badge'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { Box } from '../../components/common/box/box'
import { HeadingSmall } from '../../components/common/copy/heading-small'

export type PrivateDreamerPathNavigationProp =
  RootStackScreenProps<'PrivateDreamerPath'>
export type PrivateDreamerPathRouteProp = RouteProp<
  RootStackParamList,
  'PrivateDreamerPath'
>

export default function PrivateDreamerPath({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDreamerPath'>) {
  const [path, setPath] = useState<Path | null>(null)
  const [pathLoading, setPathLoading] = useState(true)

  const [dreams, setDreams] = useState<FastDream[]>([])
  const [dreamsLoading, setDreamsLoading] = useState(true)

  const [waypoints, setWaypoints] = useState<Waypoint[]>([])
  const [waypointsLoading, setWaypointsLoading] = useState(true)

  const loading = !path || pathLoading || dreamsLoading || waypointsLoading

  const pathId = route.params.id || ''
  const appUser = useSelector(selectUser)
  const privateGroupId = useSelector(selectPrivateGroupId)
  const imageBgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const pageWidth = Dimensions.get('window').width
  const { t } = useTranslation()
  const isWeb = Platform.OS === 'web'

  console.log('path', path)
  console.log('dreams', dreams)
  console.log('waypoints', waypoints)

  useEffect(() => {
    PathService.getPath(pathId).then((path) => {
      setPath(path)
      setPathLoading(false)
    })
  }, [pathId])

  useEffect(() => {
    if (!pathId) {
      return
    }
    WaypointService.getWaypoints(pathId).then((waypoints) => {
      setWaypoints(waypoints)
      setWaypointsLoading(false)
    })
  }, [pathId])

  useEffect(() => {
    if (!pathId) {
      return
    }
    FastDreamService.getPathDreams(pathId).then((dreams) => {
      setDreams(dreams)
      setDreamsLoading(false)
    })
  }, [pathId])

  if (loading) {
    return <Loading />
  }

  const analyzePath = () => {
    if (!path) {
      return
    }

    WaypointService.analyzePath(path.id).then((waypoint) => {
      setWaypoints([waypoint, ...waypoints])
    })
  }

  const renderDreamItem: ListRenderItem<FastDream> = ({ item, index }) => {
    return appUser && privateGroupId ? (
      <FeedItemDream
        key={item.id}
        route={route}
        dream={item}
        index={index}
        privateGroupId={privateGroupId}
        appUser={appUser}
        activeGroupId={privateGroupId}
      />
    ) : null
  }

  const renderWaypointItem: ListRenderItem<Waypoint> = ({ item, index }) => {
    return (
      <Box>
        <HeadingSmall>{item.name}</HeadingSmall>
        <BodyText>{item.description}</BodyText>
      </Box>
    )
  }

  // TAB CONTENTS
  const DreamTabContents = () => {
    if (dreams.length == 0) {
      return <EmptyFeedPage hideExtraContent={true} />
    }

    return (
      <Tabs.FlatList
        data={dreams}
        renderItem={renderDreamItem}
        keyExtractor={(item) => item.id as string}
        contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      />
    )
  }

  const InsightTabContents = () => {
    const latestDreamDate =
      dreams.length > 0 ? new Date(dreams[0].sharedAt) : new Date()
    const latestWaypointDate =
      waypoints.length > 0 ? new Date(waypoints[0].createdAt) : null

    console.log('latestDreamDate', latestDreamDate)
    console.log('latestWaypointDate', latestWaypointDate)

    const canAnalyze =
      latestDreamDate &&
      (!latestWaypointDate ||
        latestDreamDate.getTime() > latestWaypointDate.getTime())

    return (
      <View
        style={{
          width: '100%',
        }}
      >
        {canAnalyze && (
          <Center>
            <ButtonPill
              buttonSize={'xs'}
              width={100}
              my={2}
              onPress={analyzePath}
            >
              Analyze
            </ButtonPill>
          </Center>
        )}
        <Tabs.FlatList
          data={waypoints}
          renderItem={renderWaypointItem}
          keyExtractor={(item) => item.id as string}
          contentContainerStyle={
            Platform.OS === 'web' ? { flex: 1 } : undefined
          }
        />
      </View>
    )
  }

  const tabs: TabProps<string>[] = [
    {
      name: 'Insights',
      children: <InsightTabContents />,
      label: capitalize(t('common.insight_plural', { count: FEW })),
    },
    {
      name: 'Dreams',
      children: <DreamTabContents />,
      label: capitalize(t('common.dream_plural', { count: FEW })),
    },
  ]

  const PathViewHeader = () => {
    if (loading) {
      return <Loading />
    }

    return (
      <View
        pointerEvents="box-none"
        style={{
          backgroundColor,
          maxWidth: WEB_MAX_WIDTH,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Center
          backgroundColor={imageBgColor}
          width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          pointerEvents="none"
        >
          <AvatarCircle
            source={{ uri: path.image || '' }}
            name={'dunno'}
            loading={false}
            disablePress={true}
            size={'2xl'}
          />
        </Center>
        <PaddedContentArea mb={4} pointerEvents="none">
          <HeadingMain mt={4} mb={4}>
            {path.name}
          </HeadingMain>
          <BodyText textAlign={'center'}>{path.description}</BodyText>
        </PaddedContentArea>
      </View>
    )
  }

  if (loading) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </View>
    )
  } else {
    return (
      <TabView
        header={PathViewHeader}
        tabs={tabs}
        scenes={{
          Dreams: DreamTabContents,
          Insights: InsightTabContents,
        }}
      />
    )
  }
}
