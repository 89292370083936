import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { Loading } from '../../layout/loading'
import { ButtonIcon } from '../buttons/button-icon'
import { useTranslation } from 'react-i18next'
import { avatarAndIconButtonSizes, Size } from '../../../core/theme'
import BoringAvatar from '../../../modules/react-native-boring-avatars'
import { ImageForUpload, UploadOptions } from './modules/upload-images/types'
import { uploadImages } from './modules/upload-images/upload-images'
import { onUploadButtonPress } from './modules/upload-images/on-onupload-button-press'
import { ElsewhereIconType } from '../../../modules/ui-helpers/icon-map'
import { IconCharacterOutlined } from '../../../assets/react-native-svg/icons/icons-tag'
import { useAuth } from '../../../contexts/auth-context'
import { Row } from '../row/row'
import { NbImage } from '../image/nb-image'
import i18n from '../../../i18n/i18nnext'
import { useColorModeValue } from 'native-base'
import {
  BLACK,
  CREAM,
  DARK_GRAY,
  DARK_MID_GRAY,
  LIGHT_BLACK,
  LIGHT_GRAY,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../constants/ui-constants'

type FieldImageUploadProps = {
  value?: ImageOnObject
  onChange: (value: ImageOnObject) => void
  iconKey?: ElsewhereIconType
  options?: UploadOptions
  setImageLoading?: (loading: boolean) => void
  setLoadingLabel?: (loadingLabel: string) => void
  isDisabled?: boolean
  size?: Size
  avatarFallbackName?: string
}

export const FieldImageUpload = ({
  value,
  onChange,
  iconKey,
  options,
  setImageLoading,
  setLoadingLabel,
  isDisabled,
  size,
  avatarFallbackName,
}: FieldImageUploadProps) => {
  // STATE
  const [loading, setLoading] = useState<boolean>(false)

  // SELECTORS
  const { authData } = useAuth()

  // HOOKS
  const { t } = useTranslation()
  const pickerBg = useColorModeValue(CREAM, LIGHT_BLACK)
  const pickerWindowBg = useColorModeValue(WHITE, BLACK)
  const pickerLinkColor = useColorModeValue(BLACK, WHITE)
  const activeTabIconColor = useColorModeValue(BLACK, WHITE)

  // VARIABLES
  const userLanguage = i18n.resolvedLanguage || 'en'
  const token = authData?.token
  const hasImage = value !== undefined && value !== null && value?.url !== null

  // STRINGS (For Cloudinary upload widget)
  // TODO: Duplicated code in multi-image-picker-with-camera.tsx
  // Also need: There are running uploads. Click OK to abort.
  const widgetText = {
    [userLanguage]: {
      // Or (e.g. drop files or upload)
      or: t('common.or'),
      menu: {
        // 'Camera'
        camera: t('symbol.Camera'),
        // 'My files'
        files: t('cloudinaryWidget.myFiles'),
      },
      camera: {
        // Take photo
        capture: t('common.takePhoto'),
        // '"Take a picture and upload it",'
        take_pic: t('cloudinaryWidget.takePic'),
        // "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.",
        explanation: t('cloudinaryWidget.takePicExplanation'),
        cancel: t('common.cancel'),
      },
      actions: {
        upload: t('common.upload'),
        next: t('common.next'),
      },
      crop: {
        // 'Crop'
        title: t('common.crop'),
        // 'Crop'
        crop_btn: t('common.crop'),
        // 'Yes'
        close_btn: t('common.yes'),
        // 'Skip'
        skip_btn: t('common.skip'),
        // 'Reset'
        reset_btn: t('common.reset'),
        // Are you sure you want to exit? Your progress has not been saved.
        close_prompt: t('warning.exitProgressNotSaved'),
        // 'Error loading image'
        image_error: t('toast.uploadImageError.title'),
        // 'either the file size exceeds the browser limit or the file may be corrupt.'
        image_data_error: t('cloudinaryWidget.imageDataError'),
        // 'Drag corner to resize'
        corner_tooltip: t('cloudinaryWidget.cornerTooltip'),
        // 'Drag handle to resize'
        handle_tooltip: t('cloudinaryWidget.handleTooltip'),
      },
      local: {
        // "Browse"
        browse: t('common.browse'),
        // 'Drag and Drop an asset here'
        dd_title_single: t('cloudinaryWidget.dragDropSingle'),
        // 'Drag and Drop assets here'
        dd_title_multi: t('cloudinaryWidget.dragDropMultiple'),
        // 'Drop a file to upload'
        drop_title_single: t('cloudinaryWidget.dropTitleSingle'),
        // 'Drop files to upload'
        drop_title_multiple: t('cloudinaryWidget.dropTitleMultiple'),
      },
      queue: {
        // "Uploading {{num}} assets",
        title_uploading_with_counter: t(
          'cloudinaryWidget.uploadingWithCounter',
        ),
        // "Processing {{num}} assets"
        title_processing_with_counter: t(
          'cloudinaryWidget.processingWithCounter',
        ),
        // "Done",
        done: t('common.done'),
        // 'Abort all'
        abort_all: t('cloudinaryWidget.abortAll'),
        // "Retry failed",
        retry_failed: t('cloudinaryWidget.retryFailed'),
      },
    },
  }

  // STYLES
  const arabicFont = {
    default: null,
    "'Noto Sans Arabic', sans-serif": {
      url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap',
      active: true,
    },
  }

  // Only Google fonts are supported, so no point doing Favorit here
  const defaultFont = {
    default: null,
    'sans-serif': {
      url: null,
      active: true,
    },
  }

  // TODO
  const font = userLanguage === 'fa' ? arabicFont : defaultFont

  const cloudinaryWidgetStyles = {
    palette: {
      window: pickerWindowBg,
      windowBorder: LIGHT_MID_GRAY,
      tabIcon: activeTabIconColor,
      menuIcons: LIGHT_MID_GRAY,
      textDark: BLACK,
      textLight: WHITE,
      link: pickerLinkColor,
      action: pickerLinkColor,
      inactiveTabIcon: LIGHT_MID_GRAY,
      error: '#F44235',
      inProgress: pickerLinkColor,
      complete: pickerLinkColor,
      sourceBg: pickerBg,
    },
    frame: {
      background: '#0E2F5B99',
    },
    fonts: font,
  }

  const onImageSelected = async (images: ImageForUpload[]) => {
    if (!images) return
    const newImages = await uploadImages(
      images,
      token || '',
      {
        fileSize: t('toast.uploadImageTooLargeError.description'),
        uploading: t('toast.uploadImageError.title'),
      },
      options,
    )

    if (newImages && newImages[0]) {
      onChange(newImages[0])
    }
    setLoading(false)
  }

  const avatarSize: Size = size || 'lg'
  const buttonSize = avatarAndIconButtonSizes[avatarSize].width

  const onDismissPicker = () => {
    setLoading(false)
  }

  return !iconKey ? (
    <Row mt={4} justifyContent={'center'}>
      <TouchableOpacity
        onPress={() => {
          onUploadButtonPress(
            options,
            onImageSelected,
            onDismissPicker,
            widgetText,
            cloudinaryWidgetStyles,
          )
          setLoading(true)
        }}
      >
        {/* LOADING */}
        {loading && <Loading width={buttonSize * 4} />}
        {/* PROFILE IMAGE */}
        {/* NO IMAGE, SHOW BORING AVATAR */}
        {!loading && !hasImage && avatarFallbackName && (
          <BoringAvatar
            size={buttonSize * 4}
            name={avatarFallbackName}
            variant="marble"
            colors={[
              CREAM,
              LIGHT_BLACK,
              DARK_MID_GRAY,
              LIGHT_MID_GRAY,
              LIGHT_GRAY,
              DARK_GRAY,
            ]}
          />
        )}
        {/* NO avatarFallbackName, SHOW DUMMY AVATAR */}
        {!loading && !hasImage && !avatarFallbackName && (
          <IconCharacterOutlined size={buttonSize} />
        )}
        {!loading && hasImage && (
          <NbImage
            source={{ uri: value.url }}
            alt={'Profile picture'}
            size={buttonSize}
            borderRadius={'full'}
          />
        )}
      </TouchableOpacity>
    </Row>
  ) : (
    <ButtonIcon
      m={0}
      p={0}
      iconKey={iconKey}
      variant={'outlined'}
      // onPress={() => showWidget(widget)}
      onPress={() => {
        onUploadButtonPress(
          options,
          onImageSelected,
          onDismissPicker,
          widgetText,
          cloudinaryWidgetStyles,
        )
        setLoading(true)
      }}
      isDisabled={isDisabled}
      size={size}
    />
  )
}
