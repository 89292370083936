import {
  CompositeScreenProps,
  CompositeNavigationProp,
  NavigatorScreenParams,
} from '@react-navigation/native'
import type {
  NativeStackScreenProps,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack'
import type {
  BottomTabScreenProps,
  BottomTabNavigationProp,
} from '@react-navigation/bottom-tabs'
import { TagTypeWithInsights } from '../../../api/frontend-types'
import { RouteProp } from '@react-navigation/core'

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>

export type RootStackNavigationProp<T extends keyof RootStackParamList> =
  NativeStackNavigationProp<RootStackParamList, T>

export type RootStackParamList = {
  About: undefined
  DeleteMyData: undefined
  SymbolDictionary: undefined
  Privacy: undefined
  Terms: undefined
  Cookies: undefined
  Invite: {
    inviteCode: string
  }
  DreamAnimationFestival: undefined
  DreamAnimationFestivalDreamView: {
    id: never
    tab?: never
    groupId?: never
    afDreamId: string
  }
  Journal: undefined
  Library: undefined
  LibraryDreamerView: {
    username: string
    userId?: never
    groupId?: never
  }
  LibraryDreamView: {
    id: string
    tab?: string
    afDreamId?: never
    groupId?: never
  }
  LibraryInsightView: {
    id: string
    isRevealing?: boolean
  }
  // Auth stack
  Home: undefined
  Login:
    | {
        email?: string
        codeSent?: boolean
        inviteCode?: string
      }
    | undefined
  MagicLogin:
    | {
        code?: string
        email?: string
      }
    | undefined
  Signup:
    | {
        inviteCode?: string
      }
    | undefined
  // Main stack
  MainTabs: NavigatorScreenParams<MainTabsParamList>
  PrivateDreamAdd: undefined
  GroupAdd: undefined
  PrivateSettings: undefined
  PrivateAboutElsewhere: undefined
  GroupSettings: {
    groupId: string
  }
  PrivateDreamerEdit: undefined
  GroupProfileEdit: {
    groupId: string
  }
  PrivateDreamEdit: {
    id: string
  }
  PrivateDraftEdit: {
    id: string
  }
  PrivateDiaryEdit: {
    id: string
  }
  PrivateDreamShare: {
    id: string
  }
  PrivateDreamScan: {
    onGoBack: (data: any) => void
  }
  PrivateDreamerProfileEdit: {
    id: string
  }
  PrivateNotificationsSettingsEdit: undefined
  PrivateSubscriptionSettingsEdit: {
    tab?: string
  }
  PrivateCreditSettingsEdit: undefined
  PrivateDreamerSettingsEdit: undefined
  PrivateDreamSettingsEdit: undefined
  Contact: undefined
  PrivatePrivacyAndTerms: undefined
  InterpretationStyle: {
    style: string
  }
  PrivateDreamerPaths: undefined
  PrivateDreamerPath: {
    id: string
  }
  PrivateDataExport: undefined
  PrivateDataImport: undefined
  PrivateSupport: undefined
  PrivateDataDelete: undefined
  PrivateSubscriptionStatusView: undefined
  PrivateAboutElsewhereTeam: undefined
  PrivateDreamerView: {
    userId: string
    username?: never
    groupId?: never
  }
  GroupDreamerView: {
    userId: string
    username?: never
    groupId: string
  }
  GroupProfileView: {
    groupId: string
  }
  GroupMembersView: {
    groupId: string
  }
  PrivateFriendPreferences: {
    username: string
  }
}

export type MainTabsScreenProps<T extends keyof MainTabsParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<MainTabsParamList, T>,
    RootStackScreenProps<keyof RootStackParamList>
  >

export type MainTabsNavigationProp<T extends keyof MainTabsParamList> =
  CompositeNavigationProp<
    BottomTabNavigationProp<MainTabsParamList, T>,
    RootStackNavigationProp<keyof RootStackParamList>
  >

export type MainTabsParamList = {
  MainStack: NavigatorScreenParams<MainStackParamList>
}

export type MainStackScreenProps<T extends keyof MainStackParamList> =
  CompositeScreenProps<
    NativeStackScreenProps<MainStackParamList, T>,
    MainTabsScreenProps<keyof MainTabsParamList>
  >

export type MainStackNavigationProp<T extends keyof MainStackParamList> =
  CompositeNavigationProp<
    NativeStackNavigationProp<MainStackParamList, T>,
    MainTabsNavigationProp<keyof MainTabsParamList>
  >

export type MainStackParamList = {
  PrivateFeed:
    | {
        from?: string
        groupId?: never
      }
    | undefined
  PrivateDreamView: {
    id: string
    afDreamId?: never
    groupId?: never
    tab?: string
  }
  PrivateInterpretationView: {
    id: string
    language?: string
  }
  PrivateTagView: {
    id: string
    groupId?: never
  }
  PrivateSymbolInterpretationView: {
    id: string
  }
  PrivateDraftsFeed:
    | {
        from?: unknown
        groupId?: never
      }
    | undefined
  PrivateDiaryFeed:
    | {
        from?: unknown
        groupId?: never
      }
    | undefined
  PrivateDashboard:
    | {
        from?: unknown
        groupId?: never
      }
    | undefined
  PrivateDraftView: {
    id: string
    groupId?: never
  }
  PrivateDiaryView: {
    id: string
    groupId?: never
  }
  PrivateInsights:
    | {
        groupId?: never
      }
    | undefined
  PrivateInsightList: {
    groupId?: never
    type: TagTypeWithInsights
  }
  PrivateInsightView: {
    groupId?: never
    id: string
    isRevealing?: boolean
  }
  PrivateAskYourDreams:
    | {
        groupId?: never
      }
    | undefined
  PrivateAskYourDreamsInsightView: {
    groupId?: never
    id: string
    isRevealing?: boolean
  }
  PrivateCharts:
    | {
        groupId?: never
      }
    | undefined
  PrivateSearch:
    | {
        groupId?: never
        username?: never
      }
    | undefined
  PrivateSymbols:
    | {
        groupId?: never
      }
    | undefined
  GroupFeed: {
    from?: unknown
    groupId: string
  }
  GroupDreamView: {
    id: string
    afDreamId?: never
    groupId: string
    tab?: string
  }
  PrivateFriends: {} | undefined
  PrivateFriend: { username: string }
  PrivateFriendSharedDreams: { username: string }
  PrivateFavoritesFeed: undefined
  GroupFavoritesFeed: {
    groupId?: string
  }
  PrivateDreamServicesView: {
    languageSpoken: string
  }
  PrivateDreamServiceView: {
    serviceId: string
  }
}
declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type SettingsLinks =
  | 'PrivateSubscriptionStatusView'
  | 'PrivateSubscriptionSettingsEdit'
  | 'PrivateCreditSettingsEdit'
  | 'PrivateDreamerSettingsEdit'
  | 'PrivateDreamSettingsEdit'
  | 'PrivateNotificationsSettingsEdit'
  | 'PrivateDreamerEdit'
  | 'PrivateDreamerProfileEdit'
  | 'PrivateDataExport'
  | 'PrivateDataImport'
  | 'PrivateDataDelete' // end of SettingsMain
  | 'PrivateAboutElsewhereTeam'
  | 'Contact'
  | 'PrivatePrivacyAndTerms' // end of PrivateAboutElsewhere
  | 'GroupProfileEdit' // end of GroupSettings

export type FeedEntryRouteProp =
  | RouteProp<
      RootStackParamList,
      'LibraryDreamerView' | 'PrivateDreamerView' | 'GroupDreamerView'
    >
  | RouteProp<
      MainStackParamList,
      | 'PrivateTagView'
      | 'PrivateFeed'
      | 'GroupFeed'
      | 'PrivateDraftsFeed'
      | 'PrivateSearch'
    >

export type InsightTabRouteProp =
  | RouteProp<
      MainStackParamList,
      'PrivateTagView' | 'PrivateInsights' | 'PrivateInsightList'
    >
  | RouteProp<
      RootStackParamList,
      'PrivateDreamerView' | 'GroupDreamerView' | 'LibraryDreamerView'
    >

export type FeedItemInsightRouteProp =
  | RouteProp<
      RootStackParamList,
      'PrivateDreamerView' | 'GroupDreamerView' | 'LibraryDreamerView'
    >
  | RouteProp<
      MainStackParamList,
      | 'PrivateAskYourDreams'
      | 'PrivateTagView'
      | 'PrivateInsights'
      | 'PrivateInsightList'
    >

export type FeedItemInterpretationRouteProp = RouteProp<
  MainStackParamList,
  'PrivateTagView'
>

export const isRootStackNav = (
  nav: any,
): nav is RootStackNavigationProp<keyof RootStackParamList> => true

export const isMainStackNav = (
  nav: any,
): nav is MainStackNavigationProp<keyof MainStackParamList> => true

export const isStackNav = (nav: any): boolean => {
  return isMainStackNav(nav) || isRootStackNav(nav)
}
