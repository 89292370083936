import axios from 'axios'
import { OpenAPI } from '../../../../../api/_openapi'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { DREAM_MAPPING_COLLECTION } from '../../../constants/constants'

export function addDreamToPublicMap(dreamId: string, token: string) {
  const mapCollection = DREAM_MAPPING_COLLECTION
  addDreamToCollectionByName(mapCollection, dreamId, token)
}

// POST /api/collections/{name}/dreams - { dreamId: "" } adds a dream to a public collection
// I believe this route is undocumented for now
async function addDreamToCollectionByName(
  collectionName: string,
  dreamId: string,
  token: string,
) {
  const requestBody = {
    dreamId: dreamId,
  }

  const route = `${OpenAPI.BASE}/api/collections/${collectionName}/dreams`

  const response = await axios({
    method: 'post',
    url: route,
    data: requestBody,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).catch((err: any) => {
    elsewhereToast.showToast({
      title: 'common.error',
      description: err.message,
      status: 'error',
    })
  })
  if (response) {
    elsewhereToast.showToast({
      title: 'Success',
      description: 'toast.dreamsShared.description',
      status: 'success',
    })

    return response.data
  } else {
    console.log('Error sharing dream to map')
    return null
  }
}
