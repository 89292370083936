import React, { useEffect, useState } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { DreamFeedList } from '../../components/composite/feed/feed-list-dream'
import { selectUser, selectUserFetchFailed } from '../../ducks/user/user'
import { syncGroupDreams } from '../../ducks/dream-tag/thunks/dream-thunks'
import { LoadingPage } from '../../components/layout/loading-page'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import { FetchFailedPage } from '../../components/layout/fetch-failed-page'
import { WelcomeMsg } from '../../components/composite/welcome-msg/welcome-msg'
import { useDispatch } from 'react-redux'
import {
  getUserHasSeenWelcomeMessage,
  setUserHasSeenWelcomeMessage,
} from '../../asyncstorage/welcome-msg'
import { selectLastSyncTime } from '../../ducks/dream-tag/dream-tag'
import { Platform } from 'react-native'
import { useUserDreams } from '../../hooks/useUserDreams'
import { FastDream } from '../../../../api/_openapi'

export type FeedNavigationProp = MainStackNavigationProp<
  'PrivateFeed' | 'GroupFeed'
>

export default function PrivateFeed({}: MainStackScreenProps<'PrivateFeed'>) {
  const lastSyncTime = useSelector(selectLastSyncTime)
  const dispatch = useDispatch<any>()
  // STATE
  const [showingWelcome, setShowingWelcome] = useState(false)
  const [userHasSeenWelcome, setUserHasSeenWelcome] = useState(true)

  // SELECTORS
  const user = useSelector(selectUser)
  const { dreams: rawDreams, loading } = useUserDreams() // Fetch raw dreams data
  const [dreams, setDreams] = useState<FastDream[]>([]) // State to store filtered dreams

  useEffect(() => {
    // Initialize the filtered dreams to the full list when rawDreams are loaded
    if (!loading) {
      setDreams(rawDreams)
    }
  }, [rawDreams, loading])

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return
    }

    // Define the custom console command
    // Expose the command to the global `window` object
    //@ts-ignore
    window.filterDreams = (filterFn) => {
      if (typeof filterFn !== 'function') {
        console.error('Please provide a valid filter function')
        return
      }
      const filteredDreams = rawDreams.filter(filterFn)
      console.log(
        'Filtered from ',
        rawDreams.length,
        ' to ',
        filteredDreams.length,
      )
      setDreams(filteredDreams)
    }

    // Cleanup when component unmounts
    return () => {
      //@ts-ignore
      delete window.filterDreams
    }
  }, [rawDreams])

  const userFetchFailed = useSelector(selectUserFetchFailed)

  // @ts-ignore
  const welcomeSrc = user?.source // TODO: not in API docs yet
  // Check if the source starts with "l:"
  const isLandingSrc = welcomeSrc?.startsWith('l:')
  const userCreatedAt = user?.createdAt

  const onRefresh = () => {
    if (!user?.userGroupId) {
      return
    }
    const time = lastSyncTime ? new Date(lastSyncTime) : new Date('1900-01-01')
    dispatch(syncGroupDreams(user.userGroupId, time.toISOString()))
  }

  // Check if user has seen welcome message
  useEffect(() => {
    getUserHasSeenWelcomeMessage().then((hasSeen) => {
      setUserHasSeenWelcome(hasSeen)
    })
  }, [])

  // Welcome message
  // If the user has a source,
  // and user.createdAt is within 3 months
  // and user has not seen welcome, show welcome
  useEffect(() => {
    if (welcomeSrc && isLandingSrc && userCreatedAt && !userHasSeenWelcome) {
      const threeMonthsAgo = new Date()
      threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90)
      const userCreatedAtDate = new Date(userCreatedAt)
      if (userCreatedAtDate > threeMonthsAgo) {
        setShowingWelcome(true)
        setUserHasSeenWelcomeMessage(true)
      }
    }
  }, [userCreatedAt, welcomeSrc, userHasSeenWelcome])

  // EFFECTS
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return
    }

    onRefresh()
  }, [user?.userGroupId])

  if (userFetchFailed) {
    return <FetchFailedPage />
  } else if (loading || !user) {
    return <LoadingPage hasQuote={true} />
  } else {
    return (
      <>
        <DreamFeedList entries={dreams} refresh={onRefresh} appUser={user} />
        {showingWelcome && (
          <WelcomeMsg
            isOpen={showingWelcome}
            onClose={() => setShowingWelcome(false)}
            source={welcomeSrc as string}
          />
        )}
      </>
    )
  }
}
